import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ApplicationInfoClient } from '@shared/data-access/common';
import { firstValueFrom } from 'rxjs';
import { AppSettingsService } from '../core/app-settings.service';

@Component({
  selector: 'lsu-infrastructure-app-info',
  templateUrl: './app-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, NgIf],
})
export class AppInfoComponent {
  apiUrl = '';
  environment = '';
  databaseName = '';
  databaseServer = '';
  serverVersion = '';
  @Input() clientVersion = '';

  constructor(
    applicationInfoClient: ApplicationInfoClient,
    private appSettingsService: AppSettingsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.initData(applicationInfoClient);
  }

  async initData(client: ApplicationInfoClient) {
    const applicationInfo = await firstValueFrom(client.getApplicationInfo());
    if (applicationInfo.environment.toLowerCase() !== 'staging' && applicationInfo.environment.toLowerCase() !== 'production') {
      this.apiUrl = `${this.appSettingsService.apiUrl}/swagger`;
      this.environment = applicationInfo.environment;
      this.databaseName = applicationInfo.database;
      this.databaseServer = applicationInfo.dataBaseServer;
    }
    this.serverVersion = applicationInfo.version;
    this.cdr.markForCheck();
  }
}
