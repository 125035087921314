<mat-form-field *ngIf="columnGroups && visibleColumns" appearance="fill" class="w-100">
  <mat-label>
    {{ "common.visible_columns" | transloco | ucfirst }}
  </mat-label>
  <mat-select multiple [ngModel]="visibleColumns" #ngModel="ngModel" (selectionChange)="updateVisibleColumns($event.value)">
    <mat-select-trigger>
      <span> {{ visibleColumns!.slice(0, 4).join(", ") }} </span>
      <span *ngIf="visibleColumns!.length > 4"> (+{{ visibleColumns.length - 4 }} {{ "common.other" | transloco }}) </span>
    </mat-select-trigger>
    <mat-optgroup *ngFor="let group of columnGroups" [label]="group.name">
      <mat-option *ngFor="let column of group.columns" [value]="column" [disabled]="isUnhideable(column)">{{ column }}</mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
