<div *ngIf="showLoader" class="w-100 d-flex justify-content-center">
  <mat-spinner diameter="60"></mat-spinner>
</div>

<mat-table
  (contentChanged)="onTableContentChanged()"
  [dataSource]="dataSource"
  [ngClass]="{ 'd-none': !dataSource.data.length, 'd-block': maxHeight, 'overflow-auto': maxHeight }"
  #matSort="matSort"
  matSort
  multiTemplateDataRows
  [lsuDynamicTableSort]="matSort"
  [columnDefinitions]="columnDefinitions"
  [useStorage]="useStorage"
  [columnId]="defaultSortColumnName"
  [order]="defaultSortDirection"
  class="w-100"
  [ngStyle]="{ 'max-height': maxHeight, 'min-height': minHeight }"
>
  <ng-container
    *ngFor="let column of columnDefinitions"
    matColumnDef="{{ column.name }}"
    [sticky]="column.sticky || false"
    [stickyEnd]="column.stickyEnd || false"
  >
    <mat-header-cell *matHeaderCellDef>
      <mat-header-cell-content
        [disableSort]="disableSort"
        [disableFilterPopover]="disableFilterPopover"
        [column]="column"
        [columnDefinitions]="columnDefinitions"
        [dataSource]="dataSource"
        [useStorage]="useStorage"
        [showClearFilter]="isFilterActive"
        (selectionChanged)="selectionChanged.emit()"
        (applyFilter)="onApplyFilter()"
      ></mat-header-cell-content>
    </mat-header-cell>
    <mat-cell [style.justify-content]="column.style" *matCellDef="let row; let i = dataIndex">
      <ng-container [matCellComponent]="this" [row]="row" [column]="column"></ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let row" [attr.colspan]="columnDefinitions.length">
      <expanded-content-wrapper [component]="expandableTableComponent!" [row]="row"></expanded-content-wrapper>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnNames; sticky: stickyHeader"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: columnNames; let i = dataIndex"
    [expandableRow]="expandableTableComponent"
    [row]="row"
    [lsuRowDefinition]="rowDefinitions"
    [class.cursor-pointer]="rowClick.observers.length"
    (click)="rowClick.emit({ row, index: i })"
  ></mat-row>
  <ng-container *ngIf="expandableTableComponent">
    <mat-row *matRowDef="let row; columns: ['expandedDetail']" [expandedRow]="expandableTableComponent" [row]="row"></mat-row>
  </ng-container>
</mat-table>
<ng-content *ngIf="dataSource.data.length > 0; else noResult"></ng-content>
<ng-template #noResult>
  <div *ngIf="dataSource.data" class="paragaph paragraph--italic lib-table--no-results py-2">{{ noResultText }}</div>
</ng-template>
