import { DestroyRef, Directive, HostBinding, HostListener, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TableExpandableComponent } from './table-expandable.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[expandableRow]', standalone: true })
export class ExpandableRowDirective<TRow> {
  @Input() row!: TRow;
  @HostBinding('class.expand-row-on-click') expandOnClick = false;
  @HostBinding('class.is-expanded') isExpanded = false;

  private _component!: TableExpandableComponent<TRow> | undefined;
  @Input() set expandableRow(value: TableExpandableComponent<TRow> | undefined) {
    value?.selectedRow$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => (this.isExpanded = x === this.row && x !== undefined));
    this._component = value;
    this.expandOnClick = value?.expandOnRowClick ?? false;
  }
  constructor(private destroyRef: DestroyRef) {}

  @HostListener('click') onRowClick() {
    if (this._component?.expandOnRowClick) {
      this._component.onRowClick(this.row);
    }
  }
}
