/* eslint-disable max-lines */
import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SafePipe } from '@shared/ui/pipes';
import { DisplayFunc } from '../Interfaces/IColumnBase';
import { IDynamicTableEvents } from '../table/table-events.component';
import { IColumnDefinition } from './column-extender';
import { IMatCellHostComponent } from './mat-cell-host.directive';
import { TooltipDirective } from './tooltip.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-cell-default',
  templateUrl: './mat-cell-default.component.html',
  standalone: true,
  imports: [TooltipDirective, NgIf, SafePipe],
})
export class MatCellDefaultComponent<TRowData, TRowVirtual> implements IMatCellHostComponent<TRowData, TRowVirtual>, OnInit {
  displayFunc!: DisplayFunc<TRowData>;
  @Input() host!: IDynamicTableEvents<TRowData, TRowVirtual>;
  @Input() row!: TRowData;
  @Input() index!: number;
  @Input() column!: IColumnDefinition<TRowData, TRowVirtual>;

  ngOnInit(): void {
    this.displayFunc = this.column._displayFunc;
  }
}
