import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoService } from '@jsverse/transloco';
import { ucFirst } from '@shared/util/code';

@Component({
  selector: 'lsu-infrastructure-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class LoadingComponent {
  loadingMessage = '';
  constructor(transLocoService: TranslocoService) {
    this.loadingMessage = ucFirst(transLocoService.translate('common.please_wait'));
  }
}
