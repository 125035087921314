export type WidthNames =
  | 'amountBig'
  | 'codeSmall'
  | 'codeNormal'
  | 'date'
  | 'datetime'
  | 'description'
  | 'email'
  | 'errorCategory'
  | 'huge'
  | 'icon'
  | 'large'
  | 'menu'
  | 'productCode'
  | 'userName';

/**
 * [columnName]: [defaultWidth, minWidth, maxWidth]
 */
export const StyleWidthTypes: { [P in WidthNames]: [number, number, number] } = {
  ['amountBig']: [150, 140, 200],
  ['codeSmall']: [50, 40, 50],
  ['codeNormal']: [70, 65, 70],
  ['date']: [140, 120, 140],
  ['datetime']: [140, 130, 140],
  ['description']: [200, 100, 2000],
  ['email']: [230, 220, 250],
  ['errorCategory']: [150, 140, 150],
  ['huge']: [120, 120, 120],
  ['icon']: [55, 55, 55],
  ['large']: [110, 100, 110],
  ['menu']: [50, 50, 50],
  ['productCode']: [160, 150, 160],
  ['userName']: [150, 160, 200],
};
