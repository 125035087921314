import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MainColors } from '../../shared/models/colors.model';
import { ThemeModel } from '../../shared/models/theme.model';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { ThemeEditorService } from '../theme-editor.service';

@Component({
  selector: 'lsf-themes-editor-palette-picker',
  templateUrl: './palette-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ColorPickerComponent],
})
export class PalettePickerComponent implements OnInit {
  mainColors: MainColors = {} as MainColors;

  constructor(
    private themeEditorService: ThemeEditorService,
    private changeDetectorRef: ChangeDetectorRef,
    destroyRef: DestroyRef,
  ) {
    this.themeEditorService.theme$.pipe(takeUntilDestroyed(destroyRef)).subscribe((theme) => this.setColors(theme));
  }

  ngOnInit() {
    const theme = this.themeEditorService.theme;
    this.setColors(theme);
  }

  private setColors(theme: ThemeModel) {
    this.mainColors = theme.mainColors;
    this.changeDetectorRef.markForCheck();
  }
}
