import { DisplayFunc, FilterFunc, IColumnBase, ValueFunc } from '../Interfaces/IColumnBase';
import { IColumnFilter } from '../column-filter/filter-column.service';
import { intCurrencyFormat, intDecimalFormat, intShortDateFormat, intShortDateTimeFormat } from './get-display-func';
type DataTypes = string | number | boolean | Date | null;

// wildcard filtering is done on display value and can be slow. because every value in the table is converted
// Exact search is done on the value.
export const getFilterFunc = <TDataColumn>(column: IColumnBase<TDataColumn>): FilterFunc<TDataColumn> =>
  column.filterFunc instanceof Function
    ? column.filterFunc
    : column.type === 'currency'
      ? compareCurrencyFunc
      : column.type === 'decimal'
        ? compareDecimalFunc
        : column.type === 'date'
          ? compareDateValueFunc
          : column.type === 'datetime'
            ? compareDateTimeValueFunc
            : compareStringValueFunc;

const compareDateValueFunc = <T>(valueFunc: ValueFunc<T>, row: T, filterValues: IColumnFilter, dFunc?: DisplayFunc<T>) => {
  const dataValue = valueFunc(row) as string | Date;
  const format = intShortDateFormat;
  const _func = (value: DataTypes) => (dFunc ? dFunc(row).toLowerCase() : value ? format(new Date(value as string)) : '');
  return compareValue(dataValue, _func, filterValues);
};

const compareDateTimeValueFunc = <T>(valueFunc: ValueFunc<T>, row: T, filterValues: IColumnFilter, dFunc?: DisplayFunc<T>) => {
  const dataValue = valueFunc(row) as string | Date;
  const format = intShortDateTimeFormat;
  const func = (value: DataTypes) => (dFunc ? dFunc(row).toLowerCase() : value ? format(new Date(value as string)) : '');
  return compareValue(dataValue, func, filterValues);
};

const compareStringValueFunc = <T>(valueFunc: ValueFunc<T>, row: T, filterValues: IColumnFilter, dFunc?: DisplayFunc<T>) => {
  const dataValue = valueFunc(row) as string | number | boolean | null;
  const func = (value: DataTypes) => (dFunc ? dFunc(row).toLowerCase() : typeof value === 'string' ? value.toLowerCase() : (value ?? '') + '');
  return compareValue(dataValue, func, filterValues);
};

const compareCurrencyFunc = <T>(valueFunc: ValueFunc<T>, row: T, filterValues: IColumnFilter, dFunc?: DisplayFunc<T>) => {
  const dataValue = valueFunc(row) as number;
  const format = intCurrencyFormat;
  const func = (value: DataTypes) => (dFunc ? dFunc(row).toLowerCase() : typeof value === 'number' ? format(dataValue) : '');
  return compareValue(dataValue, func, filterValues);
};
const compareDecimalFunc = <T>(valueFunc: ValueFunc<T>, row: T, filterValues: IColumnFilter, dFunc?: DisplayFunc<T>) => {
  const dataValue = valueFunc(row) as number;
  const format = intDecimalFormat;
  const func = (value: DataTypes) => (dFunc ? dFunc(row).toLowerCase() : typeof value === 'number' ? format(dataValue) : '');
  return compareValue(dataValue, func, filterValues);
};

const compareValue = (dataValue: DataTypes, displayFunc: (value: DataTypes) => string, filterValues: IColumnFilter) => {
  const hashSet = filterValues.exact;
  switch (typeof dataValue) {
    case 'number':
    case 'string':
    case 'boolean':
      if (hashSet.has(dataValue)) {
        return true;
      }
      break;
    default:
      if (hashSet.has((dataValue ?? '') + '')) {
        return true;
      }
  }

  let dataValueString: string | undefined;
  for (const filterValue of filterValues.wildcardsLowerCase) {
    dataValueString ??= displayFunc(dataValue);
    if (dataValueString.includes(filterValue)) {
      return true;
    }
  }

  return false;
};
