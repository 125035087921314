import { DestroyRef, Directive, Input, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TableExpandableComponent } from './table-expandable.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[expandedContent]',
  standalone: true,
})
export class ContentDirective<TRow> {
  @Input() row!: TRow;
  @Input() set expandedContent(value: TableExpandableComponent<TRow>) {
    value.selectedRow$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (x === this.row && value.tplRef?.createEmbeddedView) {
        // the view may already exist in another row. We move/create the content to the right row
        const view = value.tplRef.createEmbeddedView({});
        this.vc.insert(view); // insert or move
      }
    });
  }
  constructor(
    private vc: ViewContainerRef,
    private destroyRef: DestroyRef,
  ) {}
}
