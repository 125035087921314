import { FileParameter, FileResponse } from '@shared/data-access/common';
import { saveAs } from 'file-saver';

export function convertToFileParameter($event: Event) {
  const target = $event.target as HTMLInputElement;
  const file = (target.files as FileList)[0];
  const fileParameter: FileParameter = { data: file, fileName: file.name };
  target.value = '';

  return fileParameter;
}

export async function readTextFile($event: Event): Promise<string> {
  const target = $event.target as HTMLInputElement;
  const file = (target.files as FileList)[0];
  target.value = '';
  return await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.readAsText(file);
  });
}
export function saveFile(file: FileResponse) {
  saveAs(file.data, file.fileName, { autoBom: false });
}

export function saveAsJson(obj: object, fileName: string, firstKey?: string) {
  const json = stringifyOrder(obj, 2, firstKey);
  const blob = new Blob([json], { type: 'application/json;charset=utf-8' });
  saveAs(blob, fileName, { autoBom: false });
}

function stringifyOrder(obj: object, space: number, firstKey?: string) {
  const allKeys = new Set<string>();
  JSON.stringify(obj, (key, value) => (allKeys.add(key), value));
  if (firstKey) {
    return JSON.stringify(
      obj,
      Array.from(allKeys).sort((k1, k2) => {
        return k1 === firstKey ? -1 : k2 === firstKey ? 1 : k1 > k2 ? 1 : 0;
      }),
      space,
    ).replace(/(\n)(\s*)(\{)(\n)(\s*)/gm, '$1$2$3' + ''.padEnd(Math.max(1, space - 1)));
  } else {
    return JSON.stringify(obj, Array.from(allKeys).sort(), space);
  }
}
