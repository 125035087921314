import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
type GuidHeader = { 'project-guid'?: string; 'report-group-guid'?: string; 'definition-guid'?: string; 'filter-guid'?: string };

@Injectable()
export class GuidInterceptor {
  static projectGuid?: string;
  static reportGroupGuid?: string;
  static definitionGuid?: string;
  static filterGuid?: string;
}

export const guidInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const setHeaders = {};
  addHeader('project-guid', GuidInterceptor.projectGuid, setHeaders);
  addHeader('report-group-guid', GuidInterceptor.reportGroupGuid, setHeaders);
  addHeader('definition-guid', GuidInterceptor.definitionGuid, setHeaders);
  addHeader('filter-guid', GuidInterceptor.filterGuid, setHeaders);
  return next(request.clone({ setHeaders }));
};

function addHeader(key: keyof GuidHeader, value: string | null | undefined, header: GuidHeader) {
  if (value) {
    header[key] = value;
  }
}
