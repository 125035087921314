<div class="d-flex flex-column h-100 position-relative" *transloco="let trl">
  <h1 mat-dialog-title>{{ trl(this.typeOfFile === documentFileType ? "assets.files_bank" : "assets.image_bank") | ucfirst }}</h1>
  <div class="position-absolute z-2 image-add-container">
    <button type="button" mat-mini-fab color="primary" (click)="drawer.toggle()" [matTooltip]="ucfirst(trl(getToolTipText(drawer.opened)))">
      <mat-icon class="position-relative">
        <span *ngIf="!drawer.opened" [@fadeInOnEnter] [@fadeOutOnLeave] class="position-absolute" style="left: 0">library_add</span>
        <span
          *ngIf="drawer.opened"
          [@fadeInOnEnter]
          [@fadeOutOnLeave]
          [@rotateInOnEnter]
          [@rotateOutOnLeave]
          class="position-absolute"
          style="left: 0"
          >close</span
        >
      </mat-icon>
    </button>
  </div>

  <lsuCloseOnEscape></lsuCloseOnEscape>
  <mat-dialog-content class="h-100">
    <mat-drawer-container class="h-100">
      <mat-drawer #drawer mode="over" class="w-100" position="end">
        <lsf-fileupload-upload [typeOfFile]="typeOfFile" class="p-3" (uploadComplete)="drawer.toggle()"></lsf-fileupload-upload>
      </mat-drawer>
      <mat-drawer-content>
        <lsf-fileupload-overview
          class="h-100"
          [multiple]="multiple"
          [typeOfFile]="typeOfFile"
          [selectedFileIds]="selectedFileIds"
          (selectionChange)="onSelectionChange($event)"
        ></lsf-fileupload-overview>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <button
      type="button"
      class="me-2"
      mat-raised-button
      color="primary"
      [mat-dialog-close]="selectedFiles"
      *ngIf="hasSelectionModel && showUseAssetButton"
      [disabled]="!selectedFiles?.length"
    >
      {{ trl(this.typeOfFile === documentFileType ? "assets._actions.use_selected_files" : "assets._actions.use_selected_images") | ucfirst }}
    </button>
    <button type="button" mat-stroked-button [mat-dialog-close]="false">{{ trl("common._actions.close") | ucfirst }}</button>
  </mat-dialog-actions>
</div>
