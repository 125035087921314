import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateThemeModel } from '@shared/data-access/common';
import { ThemingService } from '../core/theming.service';
import { ThemeDialogComponent } from '../editor/theme-dialog/theme-dialog.component';
import { ThemeModel } from '../shared/models/theme.model';
import { SvgPreviewComponent } from '../shared/svg-preview/svg-preview.component';
import { ThemePickerConfig } from './theme-picker.model';

@Component({
  selector: 'lsf-themes-theme-picker',
  templateUrl: './theme-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatMenuModule, MatIconModule, MatDividerModule, NgFor, SvgPreviewComponent],
})
export class ThemePickerComponent implements OnInit {
  themes: ThemeModel[] = [];
  selectedTheme: ThemeModel | null = null;

  @Input() label: string | null = null;
  @Input() activeThemeOnChange = true;
  @Input() projectGuid: string | null = null;
  @Input() themePickerConfig?: ThemePickerConfig;
  @Input() set theme(theme) {
    this.selectedTheme = !theme ? this.themingService.activeTheme : !theme.isOrphan ? theme : null;
    this.changeDetectorRef.markForCheck();
    this._theme = theme;
    if (!theme && this.selectedTheme) {
      this.changeTheme.emit(this.selectedTheme);
    }
  }
  get theme() {
    return this._theme;
  }

  @Output() changeTheme: EventEmitter<ThemeModel> = new EventEmitter<ThemeModel>();

  private _theme?: ThemeModel;

  constructor(
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private themingService: ThemingService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.themes = this.themingService.themes;
    if (!this.selectedTheme) {
      this.selectedTheme = this.theme ? this.theme : this.themingService.activeTheme;
    }
  }
  onAddTheme() {
    ThemeDialogComponent.open(this.matDialog, { maxWidth: 900, maxHeight: '90vh' });
  }
  async onEditTheme() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = 900;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.data = { projectGuid: this.projectGuid, theme: this.selectedTheme };
    await ThemeDialogComponent.open(this.matDialog, dialogConfig);
  }

  onSetTheme(theme: ThemeModel) {
    if (this.projectGuid) {
      // apply theme on project
      const themeModel: UpdateThemeModel = { projectGuid: this.projectGuid, theme: JSON.parse(JSON.stringify(theme)) };
      this.setProjectTheme(themeModel);
    } else {
      // load theme as preview
      this.selectedTheme = theme;
      if (this.activeThemeOnChange) {
        this.themingService.activeTheme = theme;
      }
      this.changeTheme.emit(theme);
    }
  }

  async setProjectTheme(updateTheme: UpdateThemeModel) {
    if (
      this.themePickerConfig && this.themePickerConfig.acceptThemeDialogConfirmation
        ? await this.themePickerConfig.acceptThemeDialogConfirmation(updateTheme)
        : true
    ) {
      updateTheme.theme.name += ` ${this.projectGuid!.substring(0, 8)}`;
      await this.themingService.updateProjectTheme(updateTheme);
      this.selectedTheme = this.themingService.activeTheme;
      this.matSnackbar.open('The theme of this questionnaire has been updated.');
    }
  }
}
