import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Subscription } from 'rxjs';
import { IPopOverCloseEvent } from '../popover/popover-ref';

export class FilterPopupRef<T = unknown> {
  valueChanged$ = new Subject<IPopOverCloseEvent>();
  subscription: Subscription;

  constructor(
    public overlay: OverlayRef,
    public data: T,
  ) {
    this.subscription = overlay.outsidePointerEvents().subscribe(() => this._close('backdropClick'));
  }

  valueChanged(): void {
    this.valueChanged$.next('changed');
  }

  close(): void {
    this._close('close');
  }

  private _close(type: IPopOverCloseEvent): void {
    this.overlay.dispose();
    this.valueChanged$.next(type);
    this.valueChanged$.complete();
    this.subscription.unsubscribe();
  }
}
