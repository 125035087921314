import { Injectable } from '@angular/core';
import { safeSessionStorage } from '@shared/util/code';
import { IColumn } from '../Interfaces/IColumn';
import { IColumnFilter } from '../column-filter/filter-column.service';

// filterservice will combine global filter with param filter with saved filter
@Injectable({ providedIn: 'root' })
export class FilterDefaultService {
  storeDynamicFilters<T, T1>(hash: number, columnDefinitions: IColumn<T, T1>[]): void {
    saveFilters(columnDefinitions, safeSessionStorage, `${hash}filter`);
  }

  getSavedFilters(hash: number): Record<string, IColumnFilter> | undefined {
    const paramFilters = this.getParamFilter();
    const filters = getSavedFilters(safeSessionStorage, `${hash}filter`);
    return { ...paramFilters, ...filters };
  }

  private getParamFilter() {
    const params = new URLSearchParams(window.location.search);
    const filters = Array.from(params).toDictionary(
      ([key]) => key,
      ([, value]) => this.createColumnFilter(value),
    );
    return filters;
  }

  private createColumnFilter(v: string): IColumnFilter {
    return { exact: new Set(), wildcards: this.getFilterString(v), wildcardsLowerCase: this.getFilterString(v.toLowerCase()) } as IColumnFilter;
  }

  private getFilterString(savedValue: string): string[] {
    return typeof savedValue === 'string' ? savedValue.split('|') : savedValue;
  }
}

export function saveFilters<T1, T2>(columnDefinitions: IColumn<T1, T2>[], storage: { setItem: (key: string, value: string) => void }, key: string) {
  const filters = extractFilters(columnDefinitions);
  const json = JSON.stringify(filters, (_, value) => (value instanceof Set ? [...value] : value));
  storage.setItem(key, json);
}

export function getSavedFilters(storage: { getItem: (key: string) => string | null }, key: string) {
  const json = storage.getItem(key);
  return parseFilters(json);
}

function extractFilters<T1, T2>(columnDefinitions: IColumn<T1, T2>[]) {
  return columnDefinitions
    .filter((x) => x.filterDisplay ?? false)
    .toDictionary(
      (x) => x.name,
      (x) => x.searchValues!,
    );
}

function parseFilters(json: string | null) {
  const filters = JSON.parse(json ?? '{}') as Record<string, IColumnFilter>;
  ensureDefaults(filters);
  return filters;
}

function ensureDefaults(filters: Record<string, IColumnFilter>) {
  Object.values(filters).forEach((value) => {
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    value.exact = Array.isArray(value.exact) ? new Set(value.exact) : new Set();
    value.wildcards ??= [];
    value.wildcardsLowerCase ??= [];
  });
}
