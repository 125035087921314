import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import tinycolor from 'tinycolor2';
import {
  ColorConfig,
  ColorPalette,
  TextColors,
  ThemeBackgroundColorType,
  ThemeMainColorType,
  ThemeTextColorType,
} from '../shared/models/colors.model';
import { ThemeModel } from '../shared/models/theme.model';
import { ThemingService } from './theming.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeColorService {
  colorPalette: ColorPalette = {} as ColorPalette;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  getColorByVariant(mainColor: ThemeMainColorType, colorVariant: string): ColorConfig {
    const mainColorPalette = this.colorPalette[mainColor];
    const color = mainColorPalette.find((color) => color.colorVariant === colorVariant);
    if (!color) {
      throw new Error('Invalid colorVariant!');
    }

    return color;
  }

  getColorHexValue(mainColor: ThemeMainColorType, colorVariant: string) {
    return this.getColorByVariant(mainColor, colorVariant).colorHexValue;
  }

  getAllColorHexValues() {
    const p = this.colorPalette.accentColor.map((x) => x.colorHexValue);
    return p.concat(this.colorPalette.primaryColor.map((x) => x.colorHexValue)).reduce((a, b) => {
      if (!a.includes(b)) {
        a.push(b);
      }
      return a;
    }, [] as string[]);
  }

  updateStyle(themeConfig: ThemeModel) {
    this.setupMainPalettes(themeConfig);
    this.setupBackgroundPalettes(themeConfig);
    this.setupTextColors(themeConfig.textColors);
  }

  /**
   * Map the color and its variant to something that we understand
   * Also check if we need to use a light or dark contrast color
   * @param tinyColorInstance
   * @param colorVariant
   * @private
   */
  private static mapColorConfig(tinyColorInstance: tinycolor.Instance, colorVariant: string): ColorConfig {
    return {
      colorVariant,
      colorHexValue: tinyColorInstance.toHexString(),
      shouldHaveDarkContrast: tinyColorInstance.isLight(),
    };
  }

  private static multiply(rgb1: tinycolor.ColorFormats.RGB, rgb2: tinycolor.ColorFormats.RGB): tinycolor.Instance {
    rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
    rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
    rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
    const { r, g, b } = rgb1;

    return tinycolor(`rgb ${r} ${g} ${b}`);
  }

  /**
   * This method generates a color palette comprised of 14 main and 14 contrast colors per the Angular material specification
   * It will allow us to have different shades of some color and we can use all of those shades in our material and non-material
   * material-design via css.
   * The configuration can never be 100% accurate to the Material stock colors, as they are sometimes hand-made by a designer
   * So this calculation will never be 100% accurate to the original colors provided in the Material design CSS files
   * @param hexColor
   * @private
   */
  private static generateColorPalette(hexColor: string): Array<ColorConfig> {
    const baseLight = tinycolor('#ffffff');
    const baseDark = ThemeColorService.multiply(tinycolor(hexColor).toRgb(), tinycolor(hexColor).toRgb());
    const baseTriad = tinycolor(hexColor).tetrad();

    return [
      ThemeColorService.mapColorConfig(tinycolor.mix(baseLight, hexColor, 12), '50'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseLight, hexColor, 30), '100'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseLight, hexColor, 50), '200'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseLight, hexColor, 70), '300'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseLight, hexColor, 85), '400'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseLight, hexColor, 100), '500'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, hexColor, 87), '600'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, hexColor, 70), '700'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, hexColor, 54), '800'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, hexColor, 25), '900'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(65), 'A100'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(55), 'A200'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(45), 'A400'),
      ThemeColorService.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(40), 'A700'),
    ];
  }

  private setupMainPalettes(themeModel: ThemeModel): void {
    const mainColors = themeModel.mainColors;
    const textColors = themeModel.textColors;

    Object.keys(mainColors).forEach((key: string) => {
      const selectedColorValue: string = mainColors[key as ThemeMainColorType];
      // TODO create selectedText color for warn color palette in the theme editor - text and background colours.
      const selectedTextColor = textColors[key as ThemeTextColorType] || '#ffffff';
      // Should be for example: --theme-primary or --theme-accent etc..
      const variableName: string = ThemingService.prependVariableName(ThemingService.convertCamelCaseToKebabCase(key));
      // Generate the palette colors
      const colorPalette: Array<ColorConfig> = ThemeColorService.generateColorPalette(selectedColorValue);
      this.colorPalette[key as ThemeMainColorType] = colorPalette;
      colorPalette.forEach((colorConfig: ColorConfig) => {
        // Destructure the color config
        const { colorVariant, colorHexValue, shouldHaveDarkContrast } = colorConfig;

        // Set the color variable
        const colorVariableName = `${variableName}-${colorVariant}`;
        this.setCssVariable(colorVariableName, colorHexValue);

        // By Angular material, contrasted colors are either white, or a darker color
        // Set the contrast color
        const contrastedColorVariableName = `${variableName}-contrast-${colorVariant}`;

        const contrastedColorValue = shouldHaveDarkContrast ? `rgba(0, 0, 0, .87)` : selectedTextColor;
        this.setCssVariable(contrastedColorVariableName, contrastedColorValue);
      });
    });

    // After setting all colors, make sure the combination serves a readable page.
    this.checkIfLegible(themeModel);
  }

  private setupBackgroundPalettes(themeModel: ThemeModel): void {
    const backgroundColors = themeModel.backgroundColors;

    Object.keys(backgroundColors).forEach((key: string) => {
      const selectedColorValue: string = backgroundColors[key as ThemeBackgroundColorType];

      const variableName: string = ThemingService.prependVariableName(ThemingService.convertCamelCaseToKebabCase('background-' + key));
      this.setCssVariable(variableName, selectedColorValue);
    });
  }

  private setupTextColors(textColors: TextColors) {
    Object.keys(textColors).forEach((mainColor: string) => {
      const selectedColorValue = textColors[mainColor as ThemeTextColorType];

      Object.keys(selectedColorValue).forEach((darkLight) => {
        const name = `${darkLight}-${ThemingService.convertCamelCaseToKebabCase(mainColor)}`;
        const variableName = `${ThemingService.prependVariableName(name)}-text`;
        const value = selectedColorValue;
        this.setCssVariable(variableName, value);
      });
    });
  }

  private checkIfLegible(themeModel: ThemeModel): void {
    const textColors = themeModel.textColors;
    const mainColors = themeModel.mainColors;
    for (const [key] of Object.entries(mainColors)) {
      const selectedColorValue: string = mainColors[key as ThemeMainColorType];
      const color = key.replace('Color', '');
      if (!tinycolor.isReadable(selectedColorValue, themeModel.backgroundColors.lightColor, { level: 'AA', size: 'large' })) {
        const selectedTextColor = textColors[key as keyof TextColors] || '#ffffff';
        document.body.classList.add(`is-not-legible-${color}`);
        document.body.classList.add(
          `is-not-legible-${color}-use-${tinycolor.isReadable(selectedTextColor, themeModel.backgroundColors.lightColor) ? 'contrast' : 'black'}`,
        );
      } else {
        document.body.classList.remove(`is-not-legible-${color}`, `is-not-legible-${color}-use-contrast`, `is-not-legible-${color}-use-black`);
      }
    }
  }

  private setCssVariable(variable: string, value: string): void {
    this.document.documentElement.style.setProperty(variable, value);
  }
}
