<ng-container *transloco="let trl">
  <div class="d-flex align-items-center mb-2">
    <a class="mb-0 pe-1 mat-headline-6" href="https://material.angular.io/guide/typography" target="_blank" rel="noopener">Fonts</a>
    <mat-icon>open_in_new</mat-icon>
  </div>
  <div class="mat-subtitle-2 mb-2">
    You can change fonts below. Fonts from headline to input are used in both questionnaires and editor environments. 'display-4' to 'display-1' can
    be used within the editing environment
  </div>
  <div class="mat-subtitle-2">
    <div>
      Font size and line height are measured in the rem unit. A brake unit is based on the font-size of the website's html tag. In most cases, this is
      16px. 1rem in the case then equals 16px. When you use a font with a size of 14px, calculate it as follows: 14 / 16 = 0.875rem.
    </div>
    <div>The distance between characters is measured by the em unit. An em unit is based on the font size of the element.</div>
    <div>
      <strong>All available fonts including an example</strong> can be seen by opening a font below and clicking on the magnifying glass for 'advanced
      search'.
    </div>
  </div>

  <lsu-dynamic-table [columns]="columnDefinitions" [data]="$any(tableData)" (buttonClick)="expandableRow.toggle($event.row)">
    <lsu-dynamic-table-expandable (expand)="edit($event)" #expandableRow>
      <div *ngIf="!!editing" class="options">
        <form #form="ngForm" class="py-3">
          <div class="d-flex align-items-center mb-6">
            <mat-form-field appearance="fill" class="w-25 mb-n3">
              <mat-label>Selected font</mat-label>

              <input
                matInput
                type="text"
                [(ngModel)]="search"
                name="search"
                [matAutocomplete]="auto"
                placeholder="Font family"
                required
                (ngModelChange)="searchAllFonts($event)"
                [ngStyle]="{ fontFamily: editing.fontFamily, fontWeight: fontWeight[editing.variant] }"
                #familyInput="ngModel"
              />
              <mat-error *ngIf="familyInput.hasError('required')">The field 'Selected font' is required.</mat-error>
            </mat-form-field>
            <button mat-icon-button color="accent" (click)="onSelectFont()" matTooltip="Search through all available fonts" class="me-3">
              <mat-icon>search</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option (onSelectionChange)="pickFont(option, allTypes)" *ngFor="let option of searchItems | async" [value]="option.family">{{
                option.family
              }}</mat-option>
            </mat-autocomplete>
            <mat-checkbox #allTypes (change)="toggleAllTypes($event)">Use for all types</mat-checkbox>
          </div>

          <div class="d-flex w-auto position-relative">
            <mat-form-field appearance="fill" class="w-25">
              <mat-label>Variant</mat-label>
              <mat-select class="variant" [(ngModel)]="editing.variant" name="variant" (ngModelChange)="setFontWeight(editing.variant)">
                <mat-option *ngFor="let v of variants" [value]="v">{{ v }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-25 ps-1 border-box">
              <mat-label>Font size</mat-label>
              <input matInput class="size" type="number" [(ngModel)]="editing.fontSize" #fontSize="ngModel" name="fontSize" required />
              <span matTextSuffix>rem</span>
              <mat-error *ngIf="fontSize.hasError('required')">The field 'Font size' is required.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-25 ps-1 border-box">
              <mat-label>Line height</mat-label>
              <input matInput class="spacing" type="number" [(ngModel)]="editing.lineHeight" #lineHeight="ngModel" name="lineHeight" required />
              <span matTextSuffix>rem</span>
              <mat-error *ngIf="lineHeight.hasError('required')">The field 'Line height' is required.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-25 ps-1 border-box">
              <mat-label>Letter spacing</mat-label>
              <input
                matInput
                class="spacing"
                type="number"
                [(ngModel)]="editing.letterSpacing"
                #letterSpacing="ngModel"
                name="letterSpacing"
                required
              />
              <span matTextSuffix>em</span>
              <mat-error *ngIf="letterSpacing.hasError('required')">The field 'Letter spacing' is required.</mat-error>
            </mat-form-field>
          </div>

          <div
            class="mb-3"
            [ngStyle]="{
              fontFamily: editing.fontFamily,
              fontWeight: fontWeight[editing.variant],
              fontSize: editing.fontSize + 'rem',
              letterSpacing: editing.letterSpacing + 'em',
              lineHeight: editing.lineHeight + 'rem',
            }"
          >
            <div>Almost before we knew it</div>
            <div>we had left the ground.</div>
          </div>

          <div class="d-flex">
            <button type="button" mat-stroked-button class="ms-auto me-3" (click)="onReset(form, expandableRow)">Cancel</button>
            <button type="button" mat-raised-button color="primary" (click)="onSubmit(form, expandableRow)">Save</button>
          </div>
        </form>
      </div>
    </lsu-dynamic-table-expandable>
  </lsu-dynamic-table>
</ng-container>
