import { Injectable } from '@angular/core';
import { AppInsightsService } from '@shared/util/infrastructure';
import { Subject } from 'rxjs';
import { IColumn } from '../Interfaces/IColumn';
import { FilterFunc, IColumnBase } from '../Interfaces/IColumnBase';
import { getDisplayFunc } from '../helpers/get-display-func';
import { getFilterFunc } from '../helpers/get-filter-func';
import { getValueFunc } from '../helpers/get-value-func';
import { IPopOverCloseEvent } from '../popover/popover-ref';
import { FilterPopupService } from './filter-popup.service';

@Injectable({ providedIn: 'root' })
export class FilterColumnService<TRowData, TRowVirtual> {
  constructor(private popover: FilterPopupService) {}

  showFilterMenu(origin: HTMLElement, filteredData: TRowData[], column: IColumn<TRowData, TRowVirtual>): Subject<IPopOverCloseEvent> {
    const filterFunc = getFilterFunc<TRowData>(column);

    const uniqueValues = this.getUniqueValues<TRowData, TRowVirtual>(column, filteredData);
    const options = this.sortValues(uniqueValues);

    const data = { options, column, filterFunc };
    return this.popover.open<IColumnFilterOptions<TRowData>>(origin, data).valueChanged$;
  }

  private sortValues(uniqueValues: IListBoxType[]): IListBoxType[] {
    const isNumber = uniqueValues[0] && uniqueValues.some((x) => typeof x.value === 'number');
    const options = isNumber
      ? uniqueValues.sort((a, b) => (a.value > b.value ? 1 : -1))
      : uniqueValues.sort((a, b) => (a.display > b.display ? 1 : -1));
    return options;
  }

  private getUniqueValues<TRowData, TRowVirtual>(column: IColumn<TRowData, TRowVirtual>, filteredData: TRowData[]): IListBoxType[] {
    const options: IListBoxType[] = [];
    const displayFunc = getDisplayFunc<TRowData, TRowVirtual>(column);
    const valueFunc = getValueFunc(column);
    filteredData.reduce((p, c) => {
      let value = valueFunc(c) ?? '';
      if (typeof value === 'boolean') {
        // eslint-disable-next-line no-self-assign
        value = value;
      } else if (value instanceof Date) {
        value = value.toISOString();
      } else if (typeof value === 'object' || typeof value === 'function') {
        AppInsightsService.trackTraceError('Unexpected value found', value);
        return p;
      }
      if (p.has(value)) {
        return p;
      }
      options.push({ value, display: displayFunc(c) });
      p.add(value);
      return p;
    }, new Set<string | number | Date | boolean>());
    return options;
  }
}

export interface IColumnFilterOptions<TRowData> {
  options: IListBoxType[];
  column: IColumnBase<TRowData>;
  filterFunc: FilterFunc<TRowData>;
}
export interface IListBoxType {
  value: string | number | boolean;
  display: string;
}
export interface IColumnFilter {
  wildcards: string[];
  wildcardsLowerCase: string[];
  exact: Set<string | number | boolean | Date | null>;
}
