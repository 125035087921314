import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { CloseOnEscapeDirective } from '../close-on-escape.directive';
import { MaximizeComponent } from '../maximize/maximize.component';

export interface DialogAlertData {
  title?: string;
  message: string;
  okButton?: string;
}

@Component({
  selector: 'lsu-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss'],
  standalone: true,
  imports: [CloseOnEscapeDirective, NgIf, MatDialogModule, MatButtonModule, MaximizeComponent],
})
export class DialogAlertComponent {
  title = '';
  message = '';
  okButton = 'Ok';
  constructor(
    public dialogRef: MatDialogRef<DialogAlertComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogAlertData,
    cdr: ChangeDetectorRef,
  ) {
    this.title = data.title ?? this.title;
    this.message = data.message;
    this.okButton = data.okButton ?? this.okButton;
    window.setTimeout(() => cdr.detectChanges(), 0); //timeout is needed. if an error is generated in the detectChanges or resolve cycle this screen would be skipped to render.
  }

  static async open(dialog: MatDialog, config: MatDialogConfig<DialogAlertData>): Promise<undefined> {
    return await firstValueFrom(dialog.open(DialogAlertComponent, config).afterClosed());
  }
}
