import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { TextColorPipe } from '../../shared/pipes/text-color.pipe';
// Todo ColorMode is not exported from the ngx-color-picker library
export declare type ColorMode = 'color' | 'c' | '1' | 'grayscale' | 'g' | '2' | 'presets' | 'p' | '3';

@Component({
  selector: 'lsf-themes-editor-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ColorPickerModule, TextColorPipe],
  providers: [ColorPickerService],
})
export class ColorPickerComponent {
  @Input() colorMode: ColorMode = 'color';

  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() presets?: string[];

  @Input() set color(color: string) {
    this._color = color;
    this.colorChange.emit(color);
  }

  get color() {
    return this._color;
  }

  private _color: string = null!;
}
