<div class="d-flex" *transloco="let trl">
  <div class="mat-form-field mb-4">
    <div class="mat-form-field-appearance-fill position-relative d-flex">
      <div class="align-self-center position-relative">
        <label class="d-inline-block upload-label" for="upload-file" role="button">
          <ng-template [ngIf]="!addFileModel?.name"
            >{{
              trl("common._sentences.Select_file_type", {
                type: typeOfFile === imageTypeOfFile ? "(.jpg, .png, .svg, .gif, .jpeg)" : "(.pdf, .docx, .xlsx, .pptx)",
              })
            }}
          </ng-template>
          <ng-template [ngIf]="addFileModel?.name">{{ addFileModel?.name }}</ng-template>

          <mat-progress-bar
            *ngIf="uploading$ | async"
            class="position-absolute"
            mode="indeterminate"
            matTooltip="{{ trl('common._sentences.Uploading_the_file') }}"
          ></mat-progress-bar>
        </label>
      </div>

      <button
        type="button"
        class="ms-2"
        mat-mini-fab
        (click)="onUploadClick($event, upload)"
        [matTooltip]="ucFirst(trl('assets._actions.add_file'))"
        matTooltipPosition="below"
        id="upload-file"
      >
        <mat-icon>upload_file</mat-icon>
      </button>
      <input
        class="d-none"
        name="upload-file"
        [accept]="
          typeOfFile === imageTypeOfFile
            ? 'image/png, image/jpeg, image/svg+xml'
            : 'application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        "
        (change)="handleFileInput($event)"
        #upload
        type="file"
      />
    </div>
  </div>
</div>
<ng-template [ngIf]="previewFileUrl" *transloco="let trl">
  <img [src]="previewFileUrl" class="img-fluid" />
  <br />
  {{ addFileModel.name }}
  <br />
  <button type="button" mat-raised-button color="primary" (click)="onStartUpload()">{{ trl("assets._actions.upload_image") | ucfirst }}</button>
</ng-template>
