import { TooltipPosition } from '@angular/material/tooltip';
import { IColumn } from '../Interfaces/IColumn';
import { ColorFunc, DisabledFunc, DisplayFunc, ValueFunc } from '../Interfaces/IColumnBase';
import { getDisplayFunc } from '../helpers/get-display-func';
import { getValueFunc } from '../helpers/get-value-func';
import { getTooltipFunc, getTooltipPositionFunc } from './tooltip.directive';

export interface IColumnDefinition<TRowData, TRowVirtual> extends IColumn<TRowData, TRowVirtual> {
  style?: string;
  _displayFunc: DisplayFunc<TRowData>;
  _valueFunc: ValueFunc<TRowData>;
  _colorFunc: ColorFunc<TRowData>;
  _disabledFunc: DisabledFunc<TRowData>;
  _tooltipFunc: (row: TRowData) => string;
  _tooltipPositionFunc: (row: TRowData) => TooltipPosition;
}
export function extendColumns<T1, T2>(columns: IColumn<T1, T2>[]): IColumnDefinition<T1, T2>[] {
  return columns.map((x) => extendColumn(x));
}
function extendColumn<T1, T2>(x: IColumn<T1, T2>): IColumnDefinition<T1, T2> {
  const color = x.color;
  return {
    ...x,
    style: x.type === 'checkbox' ? 'center' : '',
    _displayFunc: getDisplayFunc(x),
    _valueFunc: getValueFunc(x),
    _colorFunc: !color || typeof color === 'string' ? () => color ?? '' : color,
    _disabledFunc: typeof x.disabled === 'function' ? x.disabled : () => x.disabled === true,
    _tooltipFunc: getTooltipFunc(x.toolTip),
    _tooltipPositionFunc: getTooltipPositionFunc(x.toolTip),
  };
}
