<div class="popover">
  <div>
    <div>
      <mat-form-field>
        <mat-label>{{ selectPlaceholder }}</mat-label>
        <input matInput #searchInput type="text" (input)="onFilterItem(searchInput.value)" />
        <button
          matSuffix
          mat-icon-button
          *ngIf="searchInput.value"
          (click)="onFilterItem(''); searchInput.value = ''"
          [matTooltip]="ucfirst(this.transLocoService.translate('common._actions.clear_filter'))"
          [matTooltipPosition]="'below'"
          [matTooltipClass]="'tk-tooltip--light'"
        >
          <mat-icon>clear</mat-icon>
        </button>
        <button matSuffix mat-icon-button *ngIf="!searchInput.value">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div>
      <div>
        <mat-checkbox [ngModel]="selectAllChecked" (change)="toggleSelectAll($event)" class="select-all">
          {{ "common._actions.select_all" | transloco | ucfirst }}
        </mat-checkbox>
      </div>
      <mat-selection-list>
        <cdk-virtual-scroll-viewport [itemSize]="itemSize" [minBufferPx]="400" [maxBufferPx]="400" #panel class="panel">
          <mat-list-option
            *cdkVirtualFor="let option of options; templateCacheSize: 0"
            (click)="selectionChanged(option.value)"
            [selected]="isSelected(option.value)"
            [value]="option.value"
            checkboxPosition="before"
          >
            <span [innerHTML]="option.display"></span>
          </mat-list-option>
        </cdk-virtual-scroll-viewport>
      </mat-selection-list>
      <div class="box-actions gap-1">
        <button
          mat-stroked-button
          (click)="onClearFilters()"
          [matTooltip]="ucfirst(this.transLocoService.translate('common._actions.clear_filter'))"
          [matTooltipPosition]="'below'"
        >
          {{ "common._actions.clear_filter" | transloco | ucfirst }}
        </button>
        <button mat-raised-button color="primary" (click)="onClose()" [matTooltip]="ucfirst(this.transLocoService.translate('common.filter'))">
          {{ "common.filter" | transloco | ucfirst }}
        </button>
      </div>
    </div>
  </div>
</div>
