import { Injectable } from '@angular/core';
import { AppInsightsService } from '@shared/util/infrastructure';
import { forkJoin, from, map, Observable, of, shareReplay } from 'rxjs';
import { FontMeta } from '../shared/models/font.model';

function request<T>(url: string) {
  return from(
    (async () => {
      const result: ProgressEvent<EventTarget> = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.timeout = 10000;
        xhr.onload = resolve;
        xhr.onerror = reject;
        xhr.send(null);
      });
      const httpResult = result.target! as unknown as XMLHttpRequest;

      if (httpResult.status !== 200) {
        AppInsightsService.trackTraceError(`google api request has status ='${httpResult.status}'`);
      }
      return JSON.parse(httpResult.response) as T;
    })(),
  );
}

@Injectable({ providedIn: 'root' })
export class FontService {
  private loading: { [key: string]: boolean } = {};
  private readonly key = 'AIzaSyASSJBABEUZcYsEixmcdQ8mz0r5aTE4vNc';
  private cachedFonts$?: Observable<FontMeta[]>;

  getFonts(category?: string, family?: string): Observable<FontMeta[]> {
    family = family?.toLowerCase();
    const filter$ = of({ category, family });
    this.cachedFonts$ ??= request<{ items: FontMeta[] }>(`https://www.googleapis.com/webfonts/v1/webfonts?key=${this.key}`).pipe(
      map((x) => x.items),
      shareReplay(1),
    );
    const getFilteredFonts = (font: FontMeta[], category?: string, family?: string) =>
      font.filter((x: FontMeta) => (category ? x.category === category : true) && (family ? x.family.toLowerCase().startsWith(family) : true));
    return forkJoin([this.cachedFonts$, filter$]).pipe(map((x) => getFilteredFonts(x[0], x[1].category, x[1].family)));
  }

  loadFont(family: string) {
    if (this.loading[family]) {
      return;
    }
    const link = document.createElement('link');
    link.onerror = () => console.log('Unable to load font:', family);
    link.href = `https://fonts.googleapis.com/css?family=${family.replace(/ /g, '+')}:300,400,500,700,800`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    this.loading[family] = true;
  }
}
