declare global {
  interface Array<T> {
    toDictionary<R>(key: (item: T) => string, value: (item: T) => R): Record<string, R>;
  }
}
export const groupBy = <T, K extends string | number | symbol>(list: T[], getKey: (item: T) => K) =>
  list.reduce(
    (a, c) => {
      const group = getKey(c);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (a[group] ??= []).push(c);
      return a;
    },
    {} as Record<K, T[]>,
  );

Array.prototype.toDictionary = function <R, T>(key: (x: T) => string, value: (x: T) => R): Record<string, R> {
  return Object.fromEntries(this.map((x) => [key(x), value(x)]));
};
