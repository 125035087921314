import { isType } from '@shared/util/code';
import tinycolor from 'tinycolor2';
import { FONT_VARIANT, FontSelection, FontStyle } from '../shared/models/font.model';
import { DefaultTheme, ThemeModel } from '../shared/models/theme.model';

export function legacyConverter(themes: ThemeModel[]) {
  themes.forEach((theme) => {
    convertTypographyLegacyValues(theme);
  });
}

export function getThemeModelFromConfig(config: ThemeModel | string): ThemeModel {
  const theme = DefaultTheme;
  if (typeof config === 'string' || Object.keys(config).length === 0) {
    return theme;
  }

  convertTypographyLegacyValues(config);

  theme.mainColors.primaryColor = config.mainColors.primaryColor;
  theme.mainColors.accentColor = config.mainColors.accentColor;
  theme.mainColors.warnColor = config.mainColors.warnColor;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  theme.textColors = config.textColors || {
    primaryColor: tinycolor(config.mainColors.primaryColor).isLight() ? 'rgba(0, 0, 0, .87)' : '#fff',
    accentColor: tinycolor(config.mainColors.accentColor).isLight() ? 'rgba(0, 0, 0, .87)' : '#fff',
  };
  theme.typographyConfig = config.typographyConfig;
  theme.id = config.id;
  theme.name = config.name;

  return theme;
}

function convertTypographyLegacyValues(themeConfig: ThemeModel) {
  convertLegacyHeading(themeConfig.typographyConfig);
  convertLegacyFontFamily(themeConfig);
  addMissingFontVariant(themeConfig);
  convertLegacyUnits(themeConfig);
}

function convertLegacyUnits(themeConfig: ThemeModel) {
  Object.keys(themeConfig.typographyConfig).forEach((levelName) => {
    if (isType(themeConfig.typographyConfig, levelName)) {
      const selectedConfig = themeConfig.typographyConfig[levelName as FontStyle];
      Object.keys(selectedConfig).forEach((key: string) => {
        const typographyConfigKey = key as keyof FontSelection;
        convertUnits(typographyConfigKey, selectedConfig);
      });
    }
  });
}

function convertUnits(typographyConfigKey: string, selectedConfig: FontSelection) {
  if (typographyConfigKey === 'fontSize' || typographyConfigKey === 'lineHeight' || typographyConfigKey === 'letterSpacing') {
    let value = selectedConfig[typographyConfigKey] as unknown as string;

    if (typeof value === 'string') {
      value = value.endsWith('rem') ? value.slice(0, value.length - 3) : value;
      value = value.endsWith('em') ? value.slice(0, value.length - 2) : value;
      (selectedConfig[typographyConfigKey] as unknown as string) = value;
    }
    if (value === 'normal' && typographyConfigKey === 'letterSpacing') {
      (selectedConfig[typographyConfigKey] as unknown as string) = '0';
    }
  }
}

function convertLegacyHeading(typographyConfig: Record<FontStyle, FontSelection>) {
  if (isType(typographyConfig, 'subheading')) {
    typographyConfig.subheading1 = typographyConfig.subheading;
    delete typographyConfig.subheading;
  }
}

function addMissingFontVariant(themeConfig: ThemeModel) {
  Object.keys(themeConfig.typographyConfig).forEach((levelName) => {
    const selectedConfig = themeConfig.typographyConfig[levelName as FontStyle];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!selectedConfig.variant) {
      selectedConfig.variant = FONT_VARIANT[selectedConfig.fontWeight];
    }
  });
}

function convertLegacyFontFamily(themeConfig: ThemeModel) {
  if (!isType(themeConfig.typographyConfig, 'fontFamily')) {
    return;
  }
  let legacyFontFamily: string = themeConfig.typographyConfig.fontFamily;

  legacyFontFamily = legacyFontFamily.split(',')[0];
  delete themeConfig.typographyConfig.fontFamily;
  if (legacyFontFamily) {
    Object.keys(themeConfig.typographyConfig).map((key) => (themeConfig.typographyConfig[key as FontStyle].fontFamily = legacyFontFamily));
  }
}
