import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BackgroundColors, MainColors } from '../models/colors.model';
import { ThemeModel } from '../models/theme.model';

// TODO: Connect to ThemeStore and refresh on update.
@Component({
  selector: 'lsf-themes-svg-preview',
  templateUrl: './svg-preview.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SvgPreviewComponent {
  mainColors: MainColors = {} as MainColors;
  backgroundColors: BackgroundColors = {} as BackgroundColors;

  @Input() set theme(theme: ThemeModel) {
    this.mainColors = theme.mainColors;
    this.backgroundColors = theme.backgroundColors;
  }
}
