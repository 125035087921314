import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NgForm } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateThemeModel } from '@shared/data-access/common';
import { FocusDirective } from '@shared/ui/directives';
import { hasRoleWithinAdminPolicy, themeNameRegExp } from '@shared/util/code';
import { UserService } from '@shared/util/user';
import { ThemingService } from '../../core/theming.service';
import { ThemeModel } from '../../shared/models/theme.model';
import { SvgPreviewComponent } from '../../shared/svg-preview/svg-preview.component';
import { ThemeEditorService } from '../theme-editor.service';

@Component({
  selector: 'lsf-themes-editor-settings',
  templateUrl: './theme-settings.component.html',
  standalone: true,
  imports: [FormsModule, MatIconModule, SvgPreviewComponent, MatFormFieldModule, MatInputModule, FocusDirective, NgIf, MatSlideToggleModule],
})
export class ThemeSettingsComponent implements AfterViewInit, OnInit {
  currentTheme: ThemeModel = {} as ThemeModel;
  storedTheme: ThemeModel | undefined;
  uniqueFormId = `form-${Math.random().toString(16).slice(2)}`;
  hasRoleWithinAdminPolicy: boolean;
  themeNameRegexp = themeNameRegExp;

  @Input() projectGuid: string | null = null;

  @Output() formId: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private themingService: ThemingService,
    public themeEditorService: ThemeEditorService,
    private matSnackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    destroyRef: DestroyRef,
    userService: UserService,
  ) {
    this.themeEditorService.theme$.pipe(takeUntilDestroyed(destroyRef)).subscribe((theme) => {
      this.currentTheme = theme;
      this.storedTheme = this.themingService.tryGetTheme(this.currentTheme.id);
    });
    this.hasRoleWithinAdminPolicy = hasRoleWithinAdminPolicy(userService.currentOrImpersonateUser.role);
  }

  ngOnInit() {
    this.currentTheme = this.themeEditorService.theme;
  }

  ngAfterViewInit() {
    this.formId.emit(this.uniqueFormId);
  }

  async onSubmit(form: NgForm) {
    if (form.valid) {
      if (this.projectGuid) {
        const themeModel: UpdateThemeModel = {
          projectGuid: this.projectGuid,
          theme: this.currentTheme,
        };
        await this.themingService.updateProjectTheme(themeModel);
      } else if (!this.storedTheme) {
        this.themeEditorService.addTheme();
        this.matSnackBar.open(`Theme '${this.currentTheme.name}' has been created.`);
      } else {
        this.themeEditorService.updateTheme();
        this.matSnackBar.open(`Theme '${this.currentTheme.name}' has been updated.`);
      }

      this.cdr.markForCheck();
    }
  }
}
