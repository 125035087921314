import { OverlayRef } from '@angular/cdk/overlay';
import { Type } from '@angular/core';
import { Subject } from 'rxjs';

export type IPopOverCloseEvent = 'backdropClick' | 'close' | 'changed' | string;

export type PopOverContent = Type<unknown>;

export class PopOverRef<TData> {
  valueChanged$ = new Subject<IPopOverCloseEvent>();

  constructor(
    public overlay: OverlayRef,
    public content: PopOverContent,
    public data: TData,
  ) {
    overlay.backdropClick().subscribe(() => this._close('backdropClick'));
  }

  valueChanged(): void {
    this.valueChanged$.next('changed');
  }

  closeEsc(): void {
    this._close('backdropClick');
  }

  close(event?: string): void {
    this._close(event || 'close');
  }

  private _close(type: IPopOverCloseEvent): void {
    this.overlay.dispose();
    this.valueChanged$.next(type);
    this.valueChanged$.complete();
  }
}
