export enum FontVariant {
  Light = 'light',
  Regular = 'normal',
  Medium = 'medium',
  Bold = 'bold',
}

export enum FontWeight {
  Light = 300,
  Regular = 400,
  Medium = 500,
  Bold = 700,
}

export const FONT_WEIGHT = {
  [FontVariant.Light]: FontWeight.Light,
  [FontVariant.Regular]: FontWeight.Regular,
  [FontVariant.Medium]: FontWeight.Medium,
  [FontVariant.Bold]: FontWeight.Bold,
};

export const FONT_VARIANT = {
  [FontWeight.Light]: FontVariant.Light,
  [FontWeight.Regular]: FontVariant.Regular,
  [FontWeight.Medium]: FontVariant.Medium,
  [FontWeight.Bold]: FontVariant.Bold,
};

export interface FontSelection {
  target: string;
  fontFamily: string;
  fontWeight: FontWeight;
  variant: FontVariant;
  fontSize: number | undefined;
  lineHeight?: number;
  letterSpacing: number;
}

export interface AllFontSelection {
  [key: string]: FontSelection;
}

export interface FontMeta {
  kind: string;
  family: string;
  category: string;
  files: unknown[];
  variants: string[];
}

export type FontStyle =
  | 'display4'
  | 'display3'
  | 'display2'
  | 'display1'
  | 'headline'
  | 'title'
  | 'subheading1'
  | 'subheading2'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'subscript';

export const DEFAULT_FONTS: Record<FontStyle, FontSelection> = {
  display4: {
    target: 'display4',
    fontFamily: 'Open Sans',
    variant: FontVariant.Light,
    fontWeight: FontWeight.Light,
    fontSize: 7,
    lineHeight: 7,
    letterSpacing: -0.05,
  },
  display3: {
    target: 'display3',
    fontFamily: 'Open Sans',
    variant: FontVariant.Light,
    fontWeight: FontWeight.Light,
    fontSize: 3.5,
    lineHeight: 3.5,
    letterSpacing: -0.02,
  },
  display2: {
    target: 'display2',
    fontFamily: 'Open Sans',
    variant: FontVariant.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 2.8125,
    lineHeight: 3,
    letterSpacing: -0.005,
  },
  display1: {
    target: 'display1',
    fontFamily: 'Open Sans',
    variant: FontVariant.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 2.125,
    lineHeight: 2.5,
    letterSpacing: 0,
  },
  headline: {
    target: 'headline',
    fontFamily: 'Open Sans',
    variant: FontVariant.Medium,
    fontWeight: FontWeight.Medium,
    fontSize: 1.5,
    lineHeight: 2.5,
    letterSpacing: 0,
  },
  title: {
    target: 'title',
    fontFamily: 'Open Sans',
    variant: FontVariant.Medium,
    fontWeight: FontWeight.Medium,
    fontSize: 1.25,
    lineHeight: 2,
    letterSpacing: 0,
  },
  subheading2: {
    target: 'subheading2',
    fontFamily: 'Open Sans',
    variant: FontVariant.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 1,
    lineHeight: 1.75,
    letterSpacing: 0,
  },
  subheading1: {
    target: 'subheading1',
    fontFamily: 'Open Sans',
    variant: FontVariant.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 0.9375,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  body2: {
    target: 'body2',
    fontFamily: 'Open Sans',
    variant: FontVariant.Bold,
    fontWeight: FontWeight.Bold,
    fontSize: 0.875,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  body1: {
    target: 'body1',
    fontFamily: 'Open Sans',
    variant: FontVariant.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 0.875,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  button: {
    target: 'button',
    fontFamily: 'Open Sans',
    variant: FontVariant.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 1,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  caption: {
    target: 'caption',
    fontFamily: 'Open Sans',
    variant: FontVariant.Light,
    fontWeight: FontWeight.Light,
    fontSize: 0.75,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  subscript: {
    target: 'subscript',
    fontFamily: 'Open Sans',
    variant: FontVariant.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 0.8,
    lineHeight: 1.0,
    letterSpacing: 0,
  },
};

export function getUnit(key: keyof FontSelection): 'rem' | 'em' | '' {
  if (key === 'fontSize' || key === 'lineHeight') {
    return 'rem';
  }
  if (key === 'letterSpacing') {
    return 'em';
  }

  return '';
}
