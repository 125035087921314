import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { PopOverContent, PopOverRef } from './popover-ref';
import { PopOverComponent } from './popover.component';

@Injectable({ providedIn: 'root' })
export class PopOverService<TData> {
  private popoverRef?: PopOverRef<TData>;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  open(origin: HTMLElement, content: PopOverContent, data: TData, width?: string | number): PopOverRef<TData> {
    const overlayRef = this.getOverlayRef(origin, width);
    const popOverRef = (this.popoverRef = new PopOverRef<TData>(overlayRef, content, data));

    const injector = this.createInjector(popOverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopOverComponent, null, injector));

    return popOverRef;
  }

  close(): void {
    if (this.popoverRef) {
      this.popoverRef.closeEsc();
    }
  }

  private createInjector(popoverRef: PopOverRef<TData>, injector: Injector): Injector {
    return Injector.create({ providers: [{ provide: PopOverRef, useValue: popoverRef }], parent: injector });
  }

  private getOverlayRef(origin: HTMLElement, width?: string | number): OverlayRef {
    const positions = this.getPositions();
    const positionStrategy = this.getPositionStrategy(origin, positions);
    const overlayRef = this.overlay.create(this.getOverlayConfig(positionStrategy, width));
    return overlayRef;
  }
  private getOverlayConfig(positionStrategy: PositionStrategy, width?: string | number): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      backdropClass: 'popover-backdrop',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getPositionStrategy(origin: HTMLElement, positions: ConnectionPositionPair[]): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withPush(false);

    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      },
    ];
  }
}
