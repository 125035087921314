import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BackgroundColors, MainColors, TextColors } from '../../shared/models/colors.model';
import { ThemeModel } from '../../shared/models/theme.model';
import { IsLegiblePipe } from '../../shared/pipes/is-legible.pipe';
import { TextColorPipe } from '../../shared/pipes/text-color.pipe';
import { ThemeEditorService } from '../theme-editor.service';

@Component({
  selector: 'lsf-themes-editor-accessibility-preview',
  templateUrl: './accessibility-preview.component.html',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, NgStyle, NgTemplateOutlet, NgClass, TextColorPipe, IsLegiblePipe],
})
export class AccessibilityPreviewComponent implements OnInit {
  mainColors: MainColors = {} as MainColors;
  textColors: TextColors = {} as TextColors;
  backgroundColors: BackgroundColors = {} as BackgroundColors;

  constructor(
    private themeEditorService: ThemeEditorService,
    private destroyRef: DestroyRef,
  ) {
    this.themeEditorService.theme$.pipe(takeUntilDestroyed(destroyRef)).subscribe((theme) => {
      this.setColors(theme);
    });
  }

  ngOnInit() {
    const theme = this.themeEditorService.theme;
    this.setColors(theme);
  }

  setColors(theme: ThemeModel) {
    this.mainColors = theme.mainColors;
    this.backgroundColors = theme.backgroundColors;
    this.textColors = theme.textColors;
  }
}
