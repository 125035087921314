<div>Select primary, accent and warning colours.</div>
<div class="mb-1">By clicking on the coloured block, you can change the colour.</div>
<div class="d-flex justify-content-between">
  <div class="me-2">
    <div class="mat-subtitle-2 mb-1">Primary colours</div>
    <lsf-themes-editor-color-picker [(color)]="mainColors.primaryColor"></lsf-themes-editor-color-picker>
  </div>
  <div class="me-2">
    <div class="mat-subtitle-2 mb-1">Accent colours</div>
    <lsf-themes-editor-color-picker [(color)]="mainColors.accentColor"></lsf-themes-editor-color-picker>
  </div>
  <div>
    <div class="mat-subtitle-2 mb-1">Warning colours</div>
    <lsf-themes-editor-color-picker [(color)]="mainColors.warnColor"></lsf-themes-editor-color-picker>
  </div>
</div>
