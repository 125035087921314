import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewChecked, Component, ContentChild, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslocoService } from '@jsverse/transloco';
import { ColorDirective } from '@shared/ui/directives';
import { ColumnName, IColumn } from '../Interfaces/IColumn';
import { IRowSettings } from '../Interfaces/IRow';
import { IColumnFilter } from '../column-filter/filter-column.service';
import { getSelectedFilters } from '../helpers/filter-to-string';
import { IColumnDefinition, extendColumns } from '../mat-cell-content/column-extender';
import { columnOrder } from '../mat-cell-content/column-order';
import { MatCellHostDirective } from '../mat-cell-content/mat-cell-host.directive';
import { MatHeaderCellContentComponent } from '../mat-header-cell-content/mat-header-cell-content.component';
import { FilterService, IFilterInfo, getTableHash } from '../services/filter.service';
import { StyleRuleService } from '../services/style-rule.service';
import { ContentWrapperComponent } from './expanded-row/content-wrapper.component';
import { ExpandableRowDirective } from './expanded-row/expandable-row.directive';
import { ExpandedRowDirective } from './expanded-row/expanded-row.directive';
import { TableExpandableComponent } from './expanded-row/table-expandable.component';
import { RowDefinitionDirective } from './row-definition.directive';
import { SortDirective } from './sort.directive';
import { ICheckboxEvent, IDynamicTableEvents, IMenuClickEvent, IRowCellClickEvent } from './table-events.component';

export type Selectable<TRowData> = TRowData & { selector: boolean };

@Component({
  selector: 'lsu-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    NgStyle,
    NgFor,
    MatHeaderCellContentComponent,
    ContentWrapperComponent,
    ExpandableRowDirective,
    ExpandedRowDirective,
    MatCellHostDirective,
    SortDirective,
    ColorDirective,
    RowDefinitionDirective,
  ],
})
export class DynamicTableComponent<TRowData, TRowVirtual> implements AfterViewChecked, IDynamicTableEvents<TRowData, TRowVirtual> {
  dataSource: MatTableDataSource<TRowData> = new MatTableDataSource<TRowData>();
  columnDefinitions: IColumnDefinition<TRowData, TRowVirtual>[] = [];
  rowDefinitions: IRowSettings<TRowData> | null = null;
  filterFunctions: IFilterInfo<TRowData>[] = [];

  @HostBinding('class') class = 'lib-table';
  @Input() defaultSortColumnName: ColumnName<TRowData & TRowVirtual> = null!;
  @Input() defaultSortDirection: SortDirection = 'asc';
  @Input() maxHeight: string = null!;
  @Input() minHeight: string = 60 + 28 + 49 + 49 + 'px'; // filter + label +  2 data rows
  @Input() showLoader = false;
  @Input() useStorage = true;
  @Input() noResultText = '';
  @Input() disableSort = false;
  @Input() disableFilterPopover = false;
  @Input() stickyHeader = true;
  @Output() rowClick = new EventEmitter<{ index: number; row: TRowData & TRowVirtual }>();
  @Output() buttonClick = new EventEmitter<IRowCellClickEvent<TRowData, TRowVirtual>>();
  @Output() menuItemClick = new EventEmitter<IMenuClickEvent<TRowData, TRowVirtual>>();
  @Output() render = new EventEmitter();
  @Output() columnsChanged = new EventEmitter();
  @Output() filterChanged = new EventEmitter<TRowData[]>();
  @Output() selectionChanged = new EventEmitter();
  @Output() checkBoxChanged = new EventEmitter<ICheckboxEvent<TRowData, TRowVirtual>>();
  @Output() contentChanged = new EventEmitter();
  @ContentChild(TableExpandableComponent) expandableTableComponent?: TableExpandableComponent<TRowData>;
  @ContentChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatTable) protected matTable!: MatTable<TRowData>;
  protected columnNames: string[] = [];

  constructor(
    translocoService: TranslocoService,
    private styleRuleService: StyleRuleService,
    private filterService: FilterService<TRowData, TRowVirtual>,
  ) {
    this.noResultText = translocoService.translate('common._sentences.No_results_found');
    this.dataSource.filter = 'trigger';
  }

  @Input() set columns(columns: Array<IColumn<TRowData, TRowVirtual>>) {
    const tableId = `id${getTableHash(columns)}`;
    this.styleRuleService.initStyle(columns, tableId);
    this.class = `lib-table ${tableId}`;
    this.columnDefinitions = extendColumns(columns);
    columnOrder(this.columnDefinitions);
    this.columnsChanged.emit();
  }

  @Input() set rows(rows: IRowSettings<TRowData>) {
    this.rowDefinitions = rows;
  }

  @Input() set data(data: TRowData[] | null) {
    this.initTable(data ?? []);
  }

  onTableContentChanged() {
    this.contentChanged.emit();
  }

  getSelectedFilters() {
    return getSelectedFilters(this.filterFunctions, this.columnDefinitions);
  }
  get isFilterActive(): boolean {
    return this.filterFunctions.length > 0;
  }

  ngAfterViewChecked(): void {
    this.matTable!.updateStickyColumnStyles();
  }

  initTable(data: TRowData[]) {
    this.dataSource.data = []; // Clear the data before sort and filter functions running
    this.updateVisibleColumns();
    this.filterService.initFilter(this.columnDefinitions);
    this.setFilterPredicate();
    this.dataSource.data = data;

    setTimeout(() => this.render.emit());
  }
  updateVisibleColumns() {
    this.columnNames = this.columnDefinitions.filter((x) => !x.isHidden).map((x) => x.name);
  }

  addRow(data: TRowData & TRowVirtual, position: 'first' | 'last' = 'last'): void {
    const elements = this.dataSource.data;
    position === 'last' ? elements.push(data) : elements.unshift(data);
    this.filterService.clearFilter(this.columnDefinitions, this.useStorage);
    this.initTable(elements);
  }

  deleteRow(row: TRowData): void {
    const elements = this.dataSource.data.filter((obj) => obj !== row);
    this.initTable(elements);
  }

  onApplyFilter(): void {
    this.applyFilter();
  }

  triggerFilter() {
    this.dataSource.filter = 'trigger'; // trigger filter logic
    this.filterChanged.emit(this.dataSource.filteredData);
  }
  updateFilter(id: ColumnName<TRowData & TRowVirtual>, filterValue: IColumnFilter | undefined): void {
    this.filterService.tryUpdateFilter(this.columnDefinitions, id, filterValue, this.useStorage);
    this.setFilterPredicate();
  }

  clearDynamicFilters(columns: IColumn<TRowData, TRowVirtual>[] = this.columnDefinitions): void {
    this.filterService.clearFilter(columns, this.useStorage);
    this.applyFilter();
  }

  private applyFilter() {
    this.setFilterPredicate();
    this.triggerFilter();
  }

  private setFilterPredicate() {
    this.filterFunctions = this.filterService.getFilterInfos(this.columnDefinitions);
    this.dataSource.filterPredicate = this.filterService.getFilterPredicate(this.filterFunctions);
  }
}
