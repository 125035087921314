import { Pipe, PipeTransform } from '@angular/core';

/**
 * Split a string by one or more characters and return an array.
 * Pipe of `string.split()` function.
 *
 * @example
 * {{ 'a-b-c' | split: '-' }}
 */
@Pipe({
  name: 'split',
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(value: string, splitBy: string): string[] {
    return value.split(splitBy);
  }
}
