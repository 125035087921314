import { BackgroundColors, MainColors, TextColors } from './colors.model';
import { DEFAULT_FONTS, FontSelection, FontStyle } from './font.model';

export interface ThemeModel {
  id: string;
  isDefault: boolean;
  isOrphan?: boolean;
  name: string;
  mainColors: MainColors;
  textColors: TextColors;
  backgroundColors: BackgroundColors;
  typographyConfig: Record<FontStyle, FontSelection>;
}

export const DefaultTheme: ThemeModel = {
  id: '1',
  isDefault: true,
  mainColors: {
    primaryColor: '#003959',
    accentColor: '#57c38d',
    warnColor: '#8f0a00',
  },
  textColors: {
    primaryColor: '#fff',
    accentColor: '#000',
  },
  backgroundColors: {
    lightColor: '#fafafa',
    darkColor: '#2c2c2c',
  },
  name: 'default-theme',
  typographyConfig: DEFAULT_FONTS,
};
