/* eslint-disable max-lines */
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ColorFunc, DisabledFunc, DisplayFunc } from '../Interfaces/IColumnBase';
import { IDynamicTableEvents } from '../table/table-events.component';
import { IColumnDefinition } from './column-extender';
import { IMatCellHostComponent } from './mat-cell-host.directive';
import { TooltipDirective } from './tooltip.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-cell-icon',
  templateUrl: './mat-cell-icon.component.html',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, TooltipDirective],
})
export class MatCellIconComponent<TRowData, TRowVirtual> implements IMatCellHostComponent<TRowData, TRowVirtual>, OnInit {
  displayFunc!: DisplayFunc<TRowData>;
  colorFunc!: ColorFunc<TRowData>;
  disabledFunc!: DisabledFunc<TRowData>;

  @Input() host!: IDynamicTableEvents<TRowData, TRowVirtual>;
  @Input() row!: TRowData;
  @Input() index!: number;
  @Input() column!: IColumnDefinition<TRowData, TRowVirtual>;

  ngOnInit(): void {
    const column = this.column;
    this.displayFunc = column._displayFunc;
    this.colorFunc = column._colorFunc;
    this.disabledFunc = column._disabledFunc;
  }

  onButtonClickEvent(event: Event): void {
    event.stopPropagation();
    this.host.buttonClick.emit({ row: this.row, column: this.column, index: this.index });
  }
}
