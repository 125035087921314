import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { ColumnFilterComponent } from './column-filter.component';
import { FilterPopupRef } from './filter-popup-ref';

@Injectable({
  providedIn: 'root',
})
export class FilterPopupService {
  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  open<T>(origin: HTMLElement, data: T): FilterPopupRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig(origin));

    // we need a ref for caller and callee. The caller needs to subscribe on the valueChanged event. And the callee should trigger this event.
    // We also piggyback the data on this object otherwise we need an extra provider
    const filterPopupRef = new FilterPopupRef<T>(overlayRef, data);

    const injector = this.createInjector(filterPopupRef, this.injector);
    overlayRef.attach(new ComponentPortal(ColumnFilterComponent, null, injector));

    return filterPopupRef;
  }

  private createInjector(filterPopupRef: FilterPopupRef, injector: Injector): Injector {
    return Injector.create({ providers: [{ provide: FilterPopupRef, useValue: filterPopupRef }], parent: injector });
  }

  private getOverlayConfig(origin: HTMLElement): OverlayConfig {
    const width = 300;
    const minWidth = origin.clientWidth;
    return new OverlayConfig({
      hasBackdrop: false,
      width,
      height: undefined,
      minWidth,
      minHeight: undefined,
      backdropClass: 'popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withPush(false);

    return positionStrategy;
  }
}
const positions: ConnectionPositionPair[] = [
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  },
];
