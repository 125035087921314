import { IColumnDefinition } from '../mat-cell-content/column-extender';
import { IFilterInfo } from '../services/filter.service';
import { intCurrencyFormat, intShortDateFormat, intShortDateTimeFormat } from './get-display-func';

export function getSelectedFilters<T1, T2>(filterFunctions: IFilterInfo<T1>[], columnDefinitions: IColumnDefinition<T1, T2>[]) {
  return filterFunctions.map((x) => {
    const columnDef = columnDefinitions.find((y) => y.name === x.columnName)!;
    return {
      columnName: x.columnName,
      columnText: columnDef.text,
      searchValues: Array.from(x.searchValues.exact)
        .map((y) => {
          switch (columnDef.type) {
            case 'date':
              return intShortDateFormat(new Date(y as Date | string | number));
            case 'datetime':
              return intShortDateTimeFormat(new Date(y as Date | string | number));
            case 'currency':
              return intCurrencyFormat(y as number);
            case 'int':
            case 'decimal':
              return y as number;
            default:
              return y;
          }
        })
        .concat(x.searchValues.wildcards)
        .join(', '),
    } as TableFilter;
  });
}
export type TableFilter = {
  columnName: string;
  columnText: string;
  searchValues: string;
};
