import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { FileModel, TypeOfFile } from '@shared/data-access/common';
import { convertToFileParameter, ucFirst } from '@shared/util/code';
import { LoadingIndicatorService } from '@shared/util/infrastructure';
import { NgStringPipesModule } from 'ngx-pipes';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { FileService } from '../core/fileupload.service';
import { resizeImage } from '../core/image-resize';

@Component({
  selector: 'lsf-fileupload-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, NgIf, MatProgressBarModule, MatTooltipModule, MatButtonModule, MatIconModule, NgStringPipesModule, AsyncPipe],
})
export class UploadComponent {
  addFileModel: FileModel = {} as FileModel;
  currentFile: Blob = null!;
  previewFileUrl?: string;
  readonly imageTypeOfFile = TypeOfFile.Image;
  readonly uploading$ = new BehaviorSubject<boolean>(false);
  @Input() typeOfFile!: TypeOfFile;
  @Output() uploadComplete: EventEmitter<void> = new EventEmitter<void>();
  protected readonly ucFirst = ucFirst;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fileService: FileService,
    private loadingIndicator: LoadingIndicatorService,
  ) {}

  handleFileInput($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (this.typeOfFile === this.imageTypeOfFile) {
      if (target.files && target.files.length > 0) {
        const inputFile = (target.files as FileList)[0];
        resizeImage(inputFile, 1080, 1080, 0.7).then((response) => {
          const resized = response as Blob;
          this.currentFile = resized!;
          this.previewFileUrl = URL.createObjectURL(resized);
          this.addFileModel.name = inputFile.name;
          this.changeDetectorRef.markForCheck();
        });
      } else {
        this.previewFileUrl = null!;
        this.addFileModel = {} as FileModel;
      }
    } else {
      const fileParameter = convertToFileParameter($event);
      this.currentFile = fileParameter.data;
      this.addFileModel.name = fileParameter.fileName;
      this.changeDetectorRef.markForCheck();
      this.onStartUpload();
    }

    this.changeDetectorRef.markForCheck();
  }

  onUploadClick(event: Event, upload: HTMLInputElement): void {
    event.stopPropagation();
    upload.click();
  }

  async onStartUpload() {
    const fileParameter = { data: this.currentFile.slice(), fileName: this.addFileModel.name };
    this.uploading$.next(true);

    this.loadingIndicator.showSpinner();
    await firstValueFrom(this.fileService.addFile(fileParameter, this.addFileModel.name, this.typeOfFile));
    this.uploading$.next(false);
    this.uploadComplete.emit();
  }
}
