<span [lsuDynamicTableTooltip]="row" [column]="column">
  <mat-checkbox
    [color]="colorFunc(row)"
    [checked]="!!valueFunc(row)"
    [indeterminate]="valueFunc(row) === null"
    (change)="change($event)"
    (click)="$event.stopPropagation()"
    [disabled]="disabledFunc(row)"
  ></mat-checkbox>
</span>
