import { Pipe, PipeTransform } from '@angular/core';
import tinycolor from 'tinycolor2';

export interface IsLegibleArgs {
  backgroundColor: string;
  size: 'small' | 'large';
  level?: 'AA' | 'AAA';
}

@Pipe({
  name: 'isLegible',
  standalone: true,
})
export class IsLegiblePipe implements PipeTransform {
  transform(foregroundColor: string, args: IsLegibleArgs): boolean {
    return this.isLegible(foregroundColor, args);
  }

  private isLegible(foregroundColor: string | undefined, args: IsLegibleArgs): boolean {
    if (!foregroundColor || !args.backgroundColor) {
      return true;
    }
    const level = args.level ? args.level : 'AA';
    return tinycolor.isReadable(foregroundColor, args.backgroundColor, { level, size: args.size });
  }
}
