import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ThemingService } from '../core/theming.service';
import { FontSelection } from '../shared/models/font.model';
import { ThemeModel } from '../shared/models/theme.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeEditorService {
  theme$: Subject<ThemeModel> = new Subject<ThemeModel>();
  fonts$: Subject<FontSelection[]> = new Subject<FontSelection[]>();
  editTheme = false;

  set theme(theme: ThemeModel) {
    this._theme = theme;
    this.theme$.next(theme);
  }

  get theme() {
    if (!this._theme) {
      this._theme = this.createThemeFromDefault();
    }
    return this._theme;
  }

  set fonts(fonts: FontSelection[]) {
    this._fonts = fonts;
  }

  get fonts() {
    return this._fonts;
  }

  private _theme?: ThemeModel;
  private _fonts: FontSelection[] = [];

  constructor(private themingService: ThemingService) {}

  createThemeFromDefault(): ThemeModel {
    const defaultTheme = JSON.parse(JSON.stringify(this.themingService.defaultTheme));

    defaultTheme.isDefault = false;
    defaultTheme.name = 'New theme';
    defaultTheme.id = `${this.getMaxId() + 1}`;

    this.theme = defaultTheme;
    return defaultTheme;
  }

  addTheme() {
    this.themingService.addTheme(this.theme);
  }

  updateTheme() {
    this.themingService.updateTheme(this.theme);
  }

  clear() {
    this.theme = null!;
    this.editTheme = false;
  }

  getMaxId(): number {
    const ids = this.themingService.themes.map((theme) => +theme.id);
    return Math.max(...ids);
  }
}
