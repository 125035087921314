import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IColumn } from '../Interfaces/IColumn';
import { PopOverRef } from '../popover/popover-ref';
const maxTextLength = 524288;
@Component({
  selector: 'lsu-dynamic-table-popover-edit',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['popover-edit.component.scss'],
  template: `
    <form>
      <mat-form-field>
        <input matInput #input [type]="htmlType" [maxLength]="maxLength" name="comment" [(ngModel)]="value" />
        <mat-hint *ngIf="maxLength < maxTextLength" align="end">{{ value.length }}/{{ maxLength }}</mat-hint>
      </mat-form-field>
    </form>
  `,
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatInputModule, NgIf],
})
export class PopOverEditComponent<TRowData extends Record<string, unknown>, TRowVirtual> implements AfterViewInit {
  @ViewChild('input', { read: ElementRef, static: false }) inputElement: ElementRef = {} as ElementRef;

  readonly row: TRowData;
  readonly column: IColumn<TRowData, TRowVirtual>;
  readonly htmlType: string;
  readonly maxLength: number;
  readonly maxTextLength = maxTextLength;
  value: string;

  constructor(private popoverRef: PopOverRef<IPopupEdit<TRowData, TRowVirtual>>) {
    const row = this.popoverRef.data.row;
    this.row = row;
    this.column = this.popoverRef.data.column;
    this.maxLength = this.popoverRef.data.column.maxLength || maxTextLength;
    this.value = (this.column.name in row ? row[this.column.name] ?? '' : '') as string;
    this.htmlType = this.popoverRef.data.column.type === 'int' ? 'number' : 'text';
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (event.shiftKey && event.key === 'Tab') {
      this.saveAndClose('previous');
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.saveAndClose('next');
    }
  }
  saveAndClose(navigation: 'next' | 'previous') {
    (this.row[this.column.name] as unknown) = this.convertValue(this.value);
    this.popoverRef.close(navigation);
  }

  convertValue(value: string) {
    if (value === '') {
      return null;
    }
    switch (this.column.type) {
      case 'int':
      case 'decimal':
      case 'currency':
        return +value;
      default:
        return value;
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => this.inputElement.nativeElement.focus(), 10);
  }
}
export interface IPopupEdit<TRowData, TRowVirtual> {
  row: TRowData;
  column: IColumn<TRowData, TRowVirtual>;
}
