import { Directive, HostBinding, Input } from '@angular/core';
import { ColorDirective, ColorSettings } from '@shared/ui/directives';
import { IRowSettings } from '../Interfaces/IRow';

@Directive({
  selector: '[lsuRowDefinition]',
  standalone: true,
  hostDirectives: [ColorDirective],
})
export class RowDefinitionDirective<TRowData> {
  @Input() set lsuRowDefinition(rowDefinition: IRowSettings<TRowData> | null) {
    this.rowDefinition = rowDefinition;
    this.setHighlight();
  }

  @Input() row!: TRowData;
  private rowDefinition: IRowSettings<TRowData> | null = null;

  constructor(private colorDirective: ColorDirective<ColorSettings>) {}

  @HostBinding('class.disabled') get disabled() {
    return this.rowDefinition?.disableFunc && this.rowDefinition.disableFunc(this.row);
  }

  private setHighlight() {
    if (this.rowDefinition?.highlightFunc && this.rowDefinition.highlightFunc(this.row)) {
      this.colorDirective.lsuDirectivesColor = this.rowDefinition.highlightFunc(this.row);
    }
  }
}
