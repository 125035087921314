import { Injectable } from '@angular/core';
import { IColumn } from '../Interfaces/IColumn';
import { DisplayFunc, FilterFunc, IColumnBase, ValueFunc } from '../Interfaces/IColumnBase';
import { IColumnFilter } from '../column-filter/filter-column.service';
import { getDisplayFunc } from '../helpers/get-display-func';
import { getFilterFunc } from '../helpers/get-filter-func';
import { getValueFunc } from '../helpers/get-value-func';
import { FilterDefaultService } from './filter-default.service';

@Injectable({ providedIn: 'root' })
export class FilterService<TRowData, TRowVirtual> {
  constructor(private filterService: FilterDefaultService) {}

  tryUpdateFilter(columns: IColumn<TRowData, TRowVirtual>[], id: string, filter: IColumnFilter | undefined, save: boolean) {
    const column = columns.find((x) => x.name === id);
    if (!column) {
      return;
    }
    this.updateFilter(columns, column, filter, save);
  }

  clearFilter(columnDefinitions: IColumn<TRowData, TRowVirtual>[], save: boolean): void {
    columnDefinitions.forEach((c) => this.updateFilter(columnDefinitions, c, undefined, save));
  }

  updateFilter(columns: IColumn<TRowData, TRowVirtual>[], column: IColumn<TRowData, TRowVirtual>, filter: IColumnFilter | undefined, save: boolean) {
    this.updateColumn(column, filter);
    if (save) {
      const hashCode = getTableHash(columns);
      this.filterService.storeDynamicFilters(hashCode, columns);
    }
  }

  initFilter(columnDefinitions: Array<IColumn<TRowData, TRowVirtual>>) {
    const hash = getTableHash(columnDefinitions);
    const savedFilter = this.filterService.getSavedFilters(hash);

    this.updateColumnDefinition(savedFilter, columnDefinitions);
  }

  updateColumnDefinition(savedFilter: Record<string, IColumnFilter> | undefined, columnDefinitions: IColumn<TRowData, TRowVirtual>[]) {
    Object.entries(savedFilter ?? {}).forEach(([key, value]) =>
      columnDefinitions.filter((x) => x.name === key).forEach((x) => this.updateColumn(x, value)),
    );
  }

  getFilterPredicate = (filterFunctions: Array<IFilterInfo<TRowData>>) => (row: TRowData) =>
    !filterFunctions.some((c) => !c.filterFunc(c.valueFunc!, row, c.searchValues, c.displayFunc));

  getFilterInfos(columnDefinitions: Array<IColumn<TRowData, TRowVirtual>>): IFilterInfo<TRowData>[] {
    return columnDefinitions.filter((x) => x.searchValues).map((x) => this.getFilterInfo(x));
  }

  private updateColumn(definition: IColumnBase<TRowData>, inputValue: IColumnFilter | undefined): void {
    const hasValues = inputValue && (inputValue.exact.size || inputValue.wildcards.length);

    definition.searchValues = hasValues ? inputValue : undefined;
    definition.filterDisplay = hasValues
      ? inputValue.wildcards.join('|') +
        Array.from(inputValue.exact)
          .map((x) => (x === '' ? '<Leeg>' : x))
          .join('|')
      : null;
  }

  private getFilterInfo(x: IColumn<TRowData, TRowVirtual>): IFilterInfo<TRowData> {
    const filterFunc = getFilterFunc<TRowData>(x);
    const valueFunc = getValueFunc<TRowData, TRowVirtual>(x);
    const displayFunc = getDisplayFunc<TRowData, TRowVirtual>(x);
    const searchValues = x.searchValues as IColumnFilter;
    const columnName = x.name;
    return { filterFunc, searchValues, columnName, valueFunc, displayFunc };
  }
}

export interface IFilterInfo<TRowData> {
  displayFunc?: DisplayFunc<TRowData>;
  filterFunc: FilterFunc<TRowData>;
  valueFunc?: ValueFunc<TRowData>;
  searchValues: IColumnFilter;
  columnName: string;
}

export function getTableHash(cols: { name: string }[]) {
  return [...cols, { name: document.location.pathname }]
    .map((x) => x.name)
    .join()
    .split('')
    .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
}
