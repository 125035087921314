import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingIndicatorService } from '../loader/loading-indicator.service';

export const loadingInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const loadingService = inject<LoadingIndicatorService>(LoadingIndicatorService);
  loadingService.onRequestStart();
  return next(request).pipe(tap((evt) => (evt instanceof HttpResponse ? loadingService.onRequestEnd() : void 0)));
};
