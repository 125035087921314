import { Directive, HostBinding, Inject, InjectionToken } from '@angular/core';

export const EditorReadOnlyMode = new InjectionToken<boolean>('Check if Editor is in read only mode');

@Directive({
  selector: '[lsuIsEditorReadonly]',
  standalone: true,
})
export class IsEditorReadonlyDirective {
  constructor(@Inject(EditorReadOnlyMode) private readonly: boolean) {}
  @HostBinding('disabled') get disabled() {
    return this.readonly;
  }
}
