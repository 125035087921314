// waarom in de shared folder. Deze methodes worden toch niet gebruikt in vragenlijst expressies?

import { isGuidRegExp } from '../source-generated-questionnaire/regular-expressions';
import { safeLocalStorage, safeSessionStorage } from '../source-generated-shared/safeLocalStorage';

function getFirstGuid(redirectUrl: string | null) {
  return redirectUrl?.split('/').find((x) => isGuidRegExp.test(x));
}

export function getProjectGuid(locationPathName: string): string | undefined {
  return getFirstGuid(locationPathName) ?? getFirstGuid(safeSessionStorage.getItem('userRequestUrl'));
}

export function getGuidsFromUrl(locationPathName: string) {
  return locationPathName
    .split('?')[0]
    .split('/')
    .filter((x) => isGuidRegExp.test(x));
}

export function getItemProjectSpecific(key: string): string | null {
  const projectKey = key + (getProjectGuid(document.location.pathname) ?? '');
  return safeLocalStorage.getItem(projectKey);
}

export function setItemProjectSpecific(key: string, value: string) {
  key += getProjectGuid(document.location.pathname);
  safeLocalStorage.setItem(key, value);
}

export function removeItemProjectSpecific(key: string) {
  key += getProjectGuid(document.location.pathname);
  safeLocalStorage.removeItem(key);
}

export function getSessionItemProjectSpecific(key: string): string | null {
  const projectKey = key + (getProjectGuid(document.location.pathname) ?? '');
  return safeSessionStorage.getItem(projectKey);
}

export function setSessionItemProjectSpecific(key: string, value: string) {
  key += getProjectGuid(document.location.pathname);
  safeSessionStorage.setItem(key, value);
}

export function getNewGuid(): string {
  let d = new Date().getTime();
  const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return guid;
}
