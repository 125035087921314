import { NgComponentOutlet } from '@angular/common';
import { Component, HostBinding, Type } from '@angular/core';
import { PopOverRef } from './popover-ref';

@Component({
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  standalone: true,
  imports: [NgComponentOutlet],
})
export class PopOverComponent {
  componentContent: Type<unknown> = null!;
  @HostBinding('style.display') display = 'inline-block';
  @HostBinding('style.width') with = '100%';

  constructor(private popOverRef: PopOverRef<unknown>) {
    this.componentContent = this.popOverRef.content;
  }
}
