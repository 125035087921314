import { DestroyRef, Directive, HostBinding, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TableExpandableComponent } from './table-expandable.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[expandedRow]', standalone: true })
export class ExpandedRowDirective<TRow> {
  @Input() row!: TRow;
  @HostBinding('class.detail-row') hide = true;
  @HostBinding('class.expanded-row') expanded = true;
  @Input() set expandedRow(value: TableExpandableComponent<TRow>) {
    value.selectedRow$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => (this.hide = x !== this.row || x === undefined));
  }
  constructor(private destroyRef: DestroyRef) {}
}
