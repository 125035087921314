export enum projectSettingsTypes {
  SurveyInactiveMessage = 'SurveyInactiveMessage',
  SurveyEditorReadonly = 'SurveyEditorReadonly',
  DefaultFromEmailAddress = 'DefaultFromEmailAddress',
  DefaultEmailSenderName = 'DefaultEmailSenderName',
  ExcelExportHiddenColumns = 'ExcelExportHiddenColumns',
  AutoCompleteResponse = 'AutoCompleteResponse',
  ResponseCompletedCallbackUrl = 'ResponseCompletedCallbackUrl',
}

export type projectSettingsProperties = {
  description: string;
  type: projectSettingsValueTypes;
  defaultValue: boolean | string;
  hint?: string;
};

export enum projectSettingsValueTypes {
  string = 'string',
  boolean = 'boolean',
  email = 'email',
  url = 'url',
  hint = 'longString',
}

export const projectSettings: Record<projectSettingsTypes, projectSettingsProperties> = {
  [projectSettingsTypes.SurveyInactiveMessage]: {
    description: 'Warning text when the survey is inactive',
    type: projectSettingsValueTypes.string,
    defaultValue: '',
  },
  [projectSettingsTypes.SurveyEditorReadonly]: {
    description: 'Editor in read-only mode',
    type: projectSettingsValueTypes.boolean,
    defaultValue: false,
  },
  [projectSettingsTypes.DefaultFromEmailAddress]: {
    description: 'Overwrite the default e-mail address of the sender',
    type: projectSettingsValueTypes.email,
    defaultValue: '',
  },
  [projectSettingsTypes.DefaultEmailSenderName]: {
    description: 'Overwrite the name of the default e-mail sender',
    type: projectSettingsValueTypes.string,
    hint: 'Using an e-mail domain which your organization does not control, or have SPF / DNS records configured for, can lead to e-mails being marked as spam, or not even delivered at all.',
    defaultValue: '',
  },
  [projectSettingsTypes.ExcelExportHiddenColumns]: {
    description: 'Columns to hide in Excel export',
    hint: 'Insert the column names comma separated (case sensitive), including the suffix.',
    type: projectSettingsValueTypes.string,
    defaultValue: '',
  },
  [projectSettingsTypes.AutoCompleteResponse]: {
    description: 'Allow auto-completing responses',
    type: projectSettingsValueTypes.boolean,
    defaultValue: true,
  },
  [projectSettingsTypes.ResponseCompletedCallbackUrl]: {
    description: 'Response completed callback URL',
    type: projectSettingsValueTypes.url,
    defaultValue: '',
  },
};

export function isAllowedToEdit(settings: { [key: string]: string } | null): boolean {
  return getProjectSettingValue(projectSettingsTypes.SurveyEditorReadonly, settings ?? {}).toString() !== 'true';
}

export function getProjectSettingValue(type: string, settings: { [key: string]: string } | null): string | boolean {
  if (settings && settings[type]) {
    return JSON.parse(settings[type]);
  }
  return projectSettings[type as keyof typeof projectSettingsTypes].defaultValue;
}
