import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { CloseOnEscapeDirective } from '@shared/ui/dialog';
import { firstValueFrom } from 'rxjs';
import { FontService } from '../../core/font.service';
import { FontMeta } from '../../shared/models/font.model';
import { GoogleFontItemComponent } from './google-font-item/google-font-item.component';

@Component({
  selector: 'lsf-themes-editor-google-font-selector',
  templateUrl: './google-font-selector.component.html',
  styleUrls: ['./google-font-selector.component.scss'],
  standalone: true,
  imports: [
    CloseOnEscapeDirective,
    MatDialogTitle,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    NgIf,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    GoogleFontItemComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    AsyncPipe,
  ],
})
export class GoogleFontSelectorComponent implements OnInit {
  filter = '';

  sample = 'Almost before we knew it we had left the ground.';

  selectedFamily: string = null!;

  constructor(
    private fontService: FontService,
    @Inject(MAT_DIALOG_DATA) public fontFamily: string,
  ) {}
  static async open(dialog: MatDialog, config: MatDialogConfig<string>): Promise<string | undefined> {
    return await firstValueFrom(dialog.open(GoogleFontSelectorComponent, config).afterClosed());
  }

  ngOnInit() {
    if (this.fontFamily) {
      this.selectedFamily = this.fontFamily;
      this.fontService.loadFont(this.fontFamily);
    }
  }

  getFonts(category: string) {
    return this.fontService.getFonts(category, this.filter);
  }

  onPickFont(f: FontMeta) {
    this.selectedFamily = f.family;
  }
}
