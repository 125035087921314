import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { SettingClient, ThemeClient, ThemeModel as ThemeConfig } from '@shared/data-access/common';
import { getItemProjectSpecific, getProjectGuid, safeLocalStorage, setItemProjectSpecific } from '@shared/util/code';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultTheme, ThemeModel } from '../shared/models/theme.model';
import { getThemeModelFromConfig, legacyConverter } from './legacy-converter';
import { ThemeColorService } from './theme-color.service';
import { TypographyService } from './typography.service';

@Injectable({
  providedIn: 'root',
})
export class InitialThemingService {
  static localStorageActiveThemeId = 'activeThemeId';
  // InitialThemingService should only be responsible for loading de default theme so that the app start in the right colors
  // now it is also responsible for defaultTheme and themes
  // the below properties should be moved to the themeService
  themes: ThemeModel[] = null!;
  defaultTheme: ThemeModel = null!;

  // move to themeService
  set activeTheme(theme) {
    const projectGuid = getProjectGuid(document.location.pathname);
    projectGuid
      ? setItemProjectSpecific(InitialThemingService.localStorageActiveThemeId, `${theme.id}`)
      : safeLocalStorage.setItem(InitialThemingService.localStorageActiveThemeId, `${theme.id}`);
    this._activeTheme = theme;
    this.initTheme(theme);
  }

  // move to themeService
  get activeTheme(): ThemeModel {
    if (this._activeTheme) {
      return this._activeTheme;
    }
    const projectGuid = getProjectGuid(document.location.pathname);
    const id = projectGuid
      ? getItemProjectSpecific(InitialThemingService.localStorageActiveThemeId)
      : safeLocalStorage.getItem(InitialThemingService.localStorageActiveThemeId);
    const selectedTheme = this.themes.find((theme) => theme.id === id!);

    return !selectedTheme ? this.defaultTheme : selectedTheme;
  }

  private themeClient: ThemeClient = {} as ThemeClient;
  private settingClient: SettingClient = {} as SettingClient;
  private _activeTheme?: ThemeModel;

  constructor(
    private httpBackend: HttpBackend,
    private themeColorService: ThemeColorService,
    private typographyService: TypographyService,
  ) {}

  initialize(baseUrl: string, projectGuid?: string): Observable<unknown> {
    this.themeClient = new ThemeClient(new HttpClient(this.httpBackend), baseUrl);
    this.settingClient = new SettingClient(new HttpClient(this.httpBackend), baseUrl);
    if (projectGuid) {
      const getProjectTheme$ = this.themeClient.getProjectTheme(projectGuid);
      const getTenantTheme$ = this.settingClient.getTenantTheme();
      return getProjectTheme$.pipe(
        switchMap((projectTheme) => {
          if (!projectTheme) {
            return this.settingClient.getTenantTheme().pipe(tap((x) => this.downloadDone(x)));
          }
          // projectTheme is already loaded (the startup theme is known)// todo remove tenantTheme
          // see https://vm-colo-ads-2022.colo.desan.net/Projects/DesanProjects/_workitems/edit/5918
          return getTenantTheme$.pipe(tap((tenantTheme) => this.downloadProjectThemeDone(tenantTheme, projectTheme)));
        }),
      );
    } else {
      return this.settingClient.getTenantTheme().pipe(tap((x) => this.downloadDone(x)));
    }
  }

  initTheme(theme: ThemeModel) {
    this.themeColorService.updateStyle(theme);
    this.typographyService.setupTypographyLevel(theme);
  }

  private downloadProjectThemeDone(tenantTheme: ThemeConfig, projectThemeConfig: ThemeConfig) {
    this.themes = this.getThemes(tenantTheme);
    legacyConverter(this.themes);
    this.activeTheme = getThemeModelFromConfig(projectThemeConfig.config);
  }

  private downloadDone(tenantTheme: ThemeConfig) {
    const activeTheme = this.getActiveTheme(tenantTheme);

    this.themes = this.getThemes(tenantTheme);
    this.themes = !this.themes.length ? [activeTheme] : this.themes;
    legacyConverter(this.themes);

    this.defaultTheme = Object.assign({}, DefaultTheme, activeTheme) as ThemeModel;

    const theme = this.activeTheme;
    this.initTheme(theme);
  }

  private getActiveTheme(x: ThemeConfig): ThemeModel {
    return !Array.isArray(x.config) ? getThemeModelFromConfig(x.config) : x.config.find((themeConfig: ThemeModel) => themeConfig.isDefault);
  }

  private getThemes(x: ThemeConfig): ThemeModel[] {
    return !Array.isArray(x.config) ? [] : x.config;
  }
}
