import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@jsverse/transloco';
import { safeLocalStorage } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { DynamicTableComponent } from '../table/dynamic-table.component';

export interface ColumnGroup {
  name: string;
  columns: string[];
}

@Component({
  selector: 'lsu-dynamic-table-visible-columns',
  templateUrl: './visible-columns.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule, TranslocoModule, NgStringPipesModule],
})
export class VisibleColumnsComponent<TRowData, TRowVirtual> {
  visibleColumns: string[] = null!;

  @Input() columnGroups!: ColumnGroup[];
  @Input() set dynamicTable(value: DynamicTableComponent<TRowData, TRowVirtual> | undefined) {
    if (value) {
      // if this component is rendered before the dynamic table, the dynamic table will be undefined
      this._dynamicTable = value;
      this.initVisibleColumns();
      this._dynamicTable.columnsChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.initVisibleColumns());
    }
  }
  /**
   * When provided, save visible columns to local storage as dynamicTable.visibleColumns.{key}. Key should be
   * in the following format `env.component`, ie: `portal.projects`, `surveyAdmin.samples`, `bm.libraries`, etc.
   **/
  @Input() localStorageKey!: string;

  private _dynamicTable!: DynamicTableComponent<TRowData, TRowVirtual>;
  private readonly localStorageLocation = 'dynamicTable.visibleColumns';

  constructor(private destroyRef: DestroyRef) {}

  updateVisibleColumns(selected: string[]): void {
    const selectedSet = new Set(selected);
    const columns = this._dynamicTable.columnDefinitions;
    const visibleColumns = new Set(columns.filter((x) => x.unhideable || selectedSet.has(x.text)));
    const orgHidden = new Set(columns.filter((x) => x.isHidden));
    columns.forEach((x) => (x.isHidden = !visibleColumns.has(x)));
    this.visibleColumns = this.getVisibleColumns(this.getSelectableColumns());

    this._dynamicTable.updateVisibleColumns();

    const newHidden = columns.filter((x) => x.isHidden && !orgHidden.has(x));
    this._dynamicTable.clearDynamicFilters(newHidden);

    if (this.localStorageKey) {
      safeLocalStorage.setItem(`${this.localStorageLocation}.${this.localStorageKey}`, this.visibleColumns.join(';'));
    }
  }

  isUnhideable(column: string): boolean {
    return this._dynamicTable.columnDefinitions.some((c) => c.text === column && c.unhideable);
  }

  private initVisibleColumns() {
    if (safeLocalStorage.getItem(`${this.localStorageLocation}.${this.localStorageKey}`)) {
      this.visibleColumns = safeLocalStorage.getItem(`${this.localStorageLocation}.${this.localStorageKey}`)!.split(';');
    } else {
      const selectableColumns = this.getSelectableColumns();
      this.hideNotSelectableColumns(selectableColumns);
      this.visibleColumns = this.getVisibleColumns(selectableColumns);
    }
  }

  private getVisibleColumns(selectableColumns: Set<string>) {
    return this._dynamicTable.columnDefinitions.filter((c) => selectableColumns.has(c.text) && !c.isHidden).map((x) => x.text);
  }

  private hideNotSelectableColumns(selectableColumns: Set<string>) {
    this._dynamicTable.columnDefinitions.filter((c) => !c.unhideable && !selectableColumns.has(c.text)).map((x) => (x.isHidden = true));
  }

  private getSelectableColumns = () => new Set(this.columnGroups.flatMap((x) => x.columns));
}
