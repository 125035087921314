import { Pipe, PipeTransform } from '@angular/core';
import tinycolor from 'tinycolor2';

@Pipe({
  name: 'textColor',
  standalone: true,
})
export class TextColorPipe implements PipeTransform {
  transform(color: string): string {
    if (!color) {
      return '';
    }

    //const lightColor =
    return tinycolor(color).isLight() ? 'rgba(0,0,0,.87)' : '#fff';
  }
}
