<mat-select style="display: none">
  <mat-form-field><input matInput name="DownloadStylesheetHack" /></mat-form-field>
</mat-select>
<ng-container *ngIf="hasFilter()">
  <div class="input-wrapper">
    <mat-form-field legacyAppearanceStandard class="mb-0 search">
      <mat-icon matPrefix>search</mat-icon>
      <input class="input-filter" matInput (keyup)="onValueChanged($event)" [value]="column.filterDisplay || ''" />
      <mat-icon class="cursor--pointer" matSuffix (click)="onExactFilterClick()" *ngIf="!disableFilterPopover">arrow_drop_down</mat-icon>
    </mat-form-field>
  </div>
</ng-container>
<ng-container *ngIf="hasSort() && !isSelector()">
  <span mat-sort-header [arrowPosition]="!column.arrowPosition ? 'after' : column.arrowPosition" [matTooltip]="getTooltip(column)">{{
    column.text
  }}</span>
</ng-container>
<ng-container *ngIf="isClear()">
  <button
    mat-icon-button
    *ngIf="showClearFilter"
    (click)="onClearFilter()"
    class="clear-button"
    [matTooltip]="ucfirst(translocoService.translate('common._actions.clear_filters_on_page'))"
    [matTooltipPosition]="'below'"
  >
    <i class="material-icons">clear_all</i>
  </button>
</ng-container>
<ng-container *ngIf="isSelector()">
  <span class="header-cell">
    <mat-checkbox
      class="mat-primary"
      (change)="onColumnSelectorChange($event.checked)"
      [checked]="columnSelectorValue"
      [matTooltip]="getTooltip(column)"
      [matTooltipPosition]="'above'"
    >
    </mat-checkbox>
  </span>
</ng-container>
<ng-container *ngIf="descriptionOnly()">
  <span class="header-cell" [matTooltip]="getTooltip(column)">{{ column.text }}</span>
</ng-container>
