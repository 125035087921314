/*************************************************************************************************
 * WARNING: code used in generated typescript. Do not change without understanding consequence
 *************************************************************************************************/
/* eslint-disable no-useless-escape */
export const urlRegexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$%&'()*+,;=.]+$/;

export const urlRegexpExtended = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?{}#[\]@!$%&'()*+,;=.]+$/;
// keep the backslash before the - this is needed since chrome adds a -v flag after patterns
export const urlRegexpRequiredHttp = /^(https?:\/\/[a-zA-Z0-9.\-]+(:[0-9]{1,5})?(\/[^\s?]*)?(\?[^\s]*)?)$/;

export const emailRegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i,
);

export const isGuidRegExp = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

export const isIpAddressOrWildcard = new RegExp(
  /^(?:\*|((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))$/,
);

export const filenameRegExp = '^[^\\/\\\\\\:\\*\\?\\"\\<\\>\\|]+$';

//Validate that a name must have a least one character and must start with a character then OPTIONAL follow by numbers, letters, and underscores
export const reportNameRegExp = /^[a-zA-Z][a-zA-Z0-9_]*$/;
export const variableReqExp = /^[a-zA-Z][a-zA-Z0-9_]*$/;
export const urlParameterReqExp = /^[a-zA-Z][a-zA-Z0-9_]*$/;

//Validate that a name must have a least two characters and must start with a character then follow by numbers, letters, and underscores
export const nodeNameReqExp = /^[a-zA-Z][a-zA-Z0-9_]+$/;

export const themeNameRegExp = /^[a-zA-Z][a-zA-Z0-9_\- ]+$/;

//Validate that a name must have a least two characters and must start with a character or underscore then follow by numbers, letters, and underscores
export const translationsKeyRegExp = /^[a-zA-Z_][a-zA-Z0-9_]+$/;

// validate a valid hex color
export const hexColorRegExp = /^#?[0-9a-fA-F]{6}$/i;
