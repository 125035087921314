import { IColumnDefinition } from './column-extender';

export function columnOrder<T1, T2>(columns: IColumnDefinition<T1, T2>[]) {
  if (columns.some((x) => x.sticky)) {
    moveStickyColumns(columns);
  }
  if (columns.some((x) => x.stickyEnd)) {
    moveStickyEndColumns(columns);
  }
}

function moveStickyColumns<T1, T2>(columns: IColumnDefinition<T1, T2>[]) {
  columns.reverse().forEach((x, i) => {
    if (x.stickyEnd) {
      columns.splice(i, 1);
      columns.splice(0, 0, x);
    }
  });
  columns.reverse();
}

function moveStickyEndColumns<T1, T2>(columns: IColumnDefinition<T1, T2>[]) {
  let i = columns.length;
  while (i--) {
    if (columns[i].stickyEnd) {
      const column = columns.splice(i, 1)[0];
      columns.push(column);
    }
  }
}
