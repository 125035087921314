import { APP_INITIALIZER, EnvironmentProviders, InjectionToken, Provider } from '@angular/core';
import { TranslocoService, provideTransloco, translocoConfig } from '@jsverse/transloco';
import { TranslocoConfig } from '@jsverse/transloco/lib/transloco.config';
import { TranslocoHttpLoader, preLoadLanguage } from './index';

export const TRANSLATED_ROLES = new InjectionToken<Record<string, string>>('TRANSLATED_ROLES');

export function translationsConfig(config?: Partial<TranslocoConfig>, translatedRoles: Record<string, string> = {}): Provider | EnvironmentProviders {
  return [
    provideTransloco({
      config: translocoConfig(config),
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: (translocoService: TranslocoService) => () => preLoadLanguage(translocoService)(),
      deps: [TranslocoService],
      multi: true,
    },
    { provide: TRANSLATED_ROLES, useValue: translatedRoles },
  ];
}
