import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { dompurifySanitize } from '@shared/util/code';

@Pipe({
  name: 'lsuPipesSafeHtml',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (!value) {
      return '';
    }

    const safeHTML = dompurifySanitize(value, true);
    return this._sanitizer.bypassSecurityTrustHtml(safeHTML);
  }
}
