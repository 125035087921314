import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { LoadingComponent } from './loading.component';

const emptyFunc = () => void 0;
@Injectable({ providedIn: 'root' })
export class LoadingIndicatorService {
  get isWaiting() {
    return !!this.ref;
  }
  private func: (isLoading: boolean) => void = emptyFunc;
  private ref: MatBottomSheetRef | null = null;
  private autoOnOff = true;
  private autoClear = true;

  constructor(private bottomSheet: MatBottomSheet) {}
  showSpinner = () => this.init();

  manualShowSpinner = () => this.initManual();

  manualHideSpinner = () => this.clear();

  clear() {
    this.reset();
    this.func = emptyFunc;
  }

  onRequestEnd() {
    if (this.autoOnOff) {
      this.reset();
    }
  }

  onRequestStart() {
    if (this.autoOnOff) {
      this.func(true);
    }
  }

  private initManual() {
    this.init(false);
    this.func(true);
  }

  private init(autoOnOff = true, autoClear = true) {
    this.autoOnOff = autoOnOff;
    this.autoClear = autoClear;
    this.func = this.showHideFunc;
  }

  private reset() {
    this.func(false);
    if (this.autoClear) {
      this.func = emptyFunc;
    }
  }

  private showHideFunc: (isLoading: boolean) => void = (value) => (value ? this.show() : this.hide());

  private hide() {
    if (this.ref) {
      this.ref.dismiss();
      this.ref = null;
    }
  }

  private show() {
    if (this.ref) {
      return;
    }

    this.ref = this.bottomSheet.open(LoadingComponent, {
      disableClose: true,
      ariaLabel: 'loading indicator',
      ariaModal: true,
      restoreFocus: false,
    });
  }
}
