/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
  defaultValues,
  getItemProjectSpecific,
  getProjectGuidFromUrl,
  getQueryStringParams,
  isType,
  removeItemProjectSpecific,
  setItemProjectSpecific,
} from '@shared/util/code';
import { firstValueFrom } from 'rxjs';
import { SampleClient } from '../../data-access/common/src/lib/core/webapi-survey';

@Injectable({ providedIn: 'root' })
export class PreferredLanguageService {
  public static availableLanguages: Record<string, string> = {};

  constructor(
    private translocoService: TranslocoService,
    private injector: Injector,
  ) {}

  async setLocaleFromSamplesAndAvailableLanguages(
    languageSets: Record<string, string>,
    excludedLanguages: string[],
    languageSetsOrder: string[],
    systemLanguage: string,
    startCode: string,
  ) {
    const languages = await this.getLanguages(startCode, getProjectGuidFromUrl());
    this.setAvailableLanguages(languageSets, excludedLanguages, languageSetsOrder, languages);
    this.setLocaleInLocalStorage(PreferredLanguageService.availableLanguages, languageSetsOrder, systemLanguage);
  }

  setAvailableLanguages(
    languageSets: Record<string, string>,
    excludedLanguages: string[],
    languageSetsOrder: string[],
    sample: Record<string, string>,
  ) {
    PreferredLanguageService.availableLanguages = languageSets;

    // Add keys to make sure every language is present in the order.
    languageSetsOrder = languageSetsOrder.concat(Object.keys(languageSets));

    let availableLocales = this.getSortedAvailableLocales(languageSets, languageSetsOrder);

    // remove languages that are excluded for this questionnaire
    excludedLanguages.forEach((x) => delete languageSets[x]);

    if (isType(sample, defaultValues.availableSampleLang)) {
      const availableLanguages = ((sample[defaultValues.availableSampleLang] as string | undefined) ?? '')
        .split(';')
        .map((x) => x.trim())
        .filter((x) => x && availableLocales.includes(x.substring(0, 5)));

      if (availableLanguages.length) {
        PreferredLanguageService.availableLanguages = {};
        Object.entries(languageSets)
          .filter(([k, _]) => availableLanguages.includes(k))
          .sort((x, y) => languageSetsOrder.indexOf(x[0]) - languageSetsOrder.indexOf(y[0]))
          .forEach(([k, v]) => (PreferredLanguageService.availableLanguages[k] = v));
      }
    }

    availableLocales = this.getSortedAvailableLocales(PreferredLanguageService.availableLanguages, languageSetsOrder);

    // Set the default language of transloco (in case the chosen language is not defined in transloco)
    this.translocoService.setDefaultLang(
      availableLocales.filter((x) => (this.translocoService.getAvailableLangs() as string[]).includes(x))[0] ??
        defaultValues.englishLanguageSet.locale,
    );
  }
  setLocaleInLocalStorage(availableLanguages: Record<string, string>, languageSetsOrder: string[], systemLanguage: string) {
    // If there is a locale in the storage then this one is leading.
    let newLocale = getItemProjectSpecific(defaultValues.locale);
    if (newLocale && Object.keys(availableLanguages).includes(newLocale)) {
      return;
    }

    removeItemProjectSpecific(defaultValues.locale);
    newLocale = null;

    // Try to use the URL parameter
    const urlParameters = getQueryStringParams() ?? {};
    const localeFromUrl = isType(urlParameters, 'lang') ? urlParameters.lang : '';
    if (localeFromUrl && Object.keys(availableLanguages).includes(localeFromUrl)) {
      newLocale = localeFromUrl;
    }

    languageSetsOrder = languageSetsOrder.filter((x) => Object.keys(availableLanguages).includes(x));

    if (!newLocale && languageSetsOrder.length) {
      newLocale = languageSetsOrder[0];
    }

    if (!newLocale && Object.keys(availableLanguages).length) {
      newLocale = Object.keys(availableLanguages)[0];
    }

    setItemProjectSpecific(defaultValues.locale, newLocale ?? systemLanguage);
  }

  private async getLanguages(startCode: string, projectGuid: string) {
    const sampleClient = this.injector.get(SampleClient);
    try {
      // To avoid errors when the start code is invalid.
      return startCode ? await firstValueFrom(sampleClient.getLanguages(projectGuid, startCode)) : {};
    } catch {
      return {};
    }
  }

  private getLocale = (language: string) => language.substring(0, 5);

  private getSortedAvailableLocales(languageSets: Record<string, string>, languageSetsOrder: string[]) {
    return Object.keys(languageSets)
      .sort((x, y) => languageSetsOrder.indexOf(x) - languageSetsOrder.indexOf(y))
      .map((x) => this.getLocale(x));
  }
}
