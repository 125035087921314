import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * Provides legacy `appearance="standard"` styling to Material Form Fields.
 *
 * - For search fields, we should create a seperate directive, *which we can continue to use*.
 * - For other forms/inputs, we should eventually refactor them to strict/typed forms anyway.
 *
 * @note Use only as a fallback for Angular upgrade, and deprecate asap.
 * @deprecated
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[legacyAppearanceStandard]',
  standalone: true,
})
export class FormFieldLegacyAppearanceStandardDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const host = this.elementRef.nativeElement;

    if (host) {
      host.style.backgroundColor = 'transparent';
      host.style.width = 'inherit';
    }
    if (host.querySelector('.mat-mdc-text-field-wrapper')) {
      host.querySelector('.mat-mdc-text-field-wrapper').style.backgroundColor = 'transparent';
      host.querySelector('.mat-mdc-text-field-wrapper').style.padding = '0px';
    }
    if (host.querySelector('.mat-mdc-form-field-focus-overlay')) {
      host.querySelector('.mat-mdc-form-field-focus-overlay').style.setProperty('opacity', '0', 'important');
    }
    if (host.querySelector('.mat-mdc-form-field-infix')) {
      host.querySelector('.mat-mdc-form-field-infix').style.setProperty('padding', '24px 0 8px', 'important');
      host.querySelector('.mat-mdc-form-field-infix').style.minHeight = 'initial';
    }
    if (host.querySelector('.mat-mdc-form-field-icon-prefix')) {
      host.querySelector('.mat-mdc-form-field-icon-prefix').style.setProperty('padding', '20px 0 4px 0', 'important');
    }
    if (host.querySelector('.mat-mdc-form-field-icon-prefix .mat-icon')) {
      host.querySelector('.mat-mdc-form-field-icon-prefix .mat-icon').style.padding = '0px';
    }
    if (host.querySelector('.mat-mdc-form-field-icon-suffix')) {
      host.querySelector('.mat-mdc-form-field-icon-suffix').style.setProperty('padding', '20px 0 4px 0', 'important');
    }
    if (host.querySelector('.mat-mdc-form-field-icon-suffix .mat-icon')) {
      host.querySelector('.mat-mdc-form-field-icon-suffix .mat-icon').style.padding = '0px';
    }
  }
}
