import { Injectable } from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { safeSessionStorage } from '@shared/util/code';
import { IColumn } from '../Interfaces/IColumn';
import { SortFunc } from '../Interfaces/IColumnBase';
import { getTableHash } from './filter.service';

@Injectable({ providedIn: 'root' })
export class SortService<TRowData, TRowVirtual> {
  initSort(
    columns: IColumn<TRowData, TRowVirtual>[],
    matSort: MatSort,
    columnId?: string,
    sortOrder?: SortDirection,
  ): Record<string, SortFunc<TRowData>> {
    this.setInitialSortColumn(getTableHash(columns), matSort, columnId, sortOrder);
    return this.getSortFunctions(columns);
  }

  setSort(hash: number, sort: Sort): void {
    if (sort.active) {
      if (sort.direction === '') {
        safeSessionStorage.removeItem(`${hash}sort`);
      } else {
        safeSessionStorage.setItem(`${hash}sort`, JSON.stringify(sort));
      }
    }
  }

  private setInitialSortColumn(hash: number, matSort: MatSort, columnId?: string, start?: SortDirection): void {
    const sortStr = safeSessionStorage.getItem(`${hash}sort`) as unknown as string;
    if (sortStr) {
      const sort = JSON.parse(sortStr);
      columnId = sort.active;
      start = sort.direction;
    }

    start = start || 'asc';
    const disableClear = false;
    const id = columnId ?? '';

    // reset state first
    matSort.sort({ id: '', start, disableClear });
    matSort.sort({ id, start, disableClear });
  }

  // sort is always done on the value not on an displayValue.
  // Column without type fallback to slowSort. Every value in de column will checked for its type
  // string will be sort lowercase
  private getSortFunctions(columns: IColumn<TRowData, TRowVirtual>[]): Record<string, SortFunc<TRowData>> {
    const sortFunctions: Record<string, SortFunc<TRowData>> = {};
    columns.forEach((x) => {
      sortFunctions[x.name] = typeof x.sortFunc === 'function' ? x.sortFunc : this.getSortFunction(x);
    });
    return sortFunctions;
  }
  private getSortFunction(x: IColumn<TRowData, TRowVirtual>): SortFunc<TRowData> {
    const valueFunc = x.valueFunc ?? ((row) => row[x.name as Extract<keyof TRowData, string>] as string | number);
    switch (x.type) {
      case 'currency':
      case 'decimal':
      case 'int':
      case 'date':
      case 'checkbox':
      case 'datetime':
        return valueFunc as SortFunc<TRowData>;
      case 'icon':
      case undefined:
        return x.valueFunc ? (x.valueFunc as SortFunc<TRowData>) : (row) => this.defaultSlowSort(row[x.name as Extract<keyof TRowData, string>]);
      case 'dynamicIcon':
      case 'menu':
      case 'component':
        return () => this.throwExpression(`Can not sort on column ${x.name} with type '${x.type}'.`);
    }
  }
  private throwExpression(errorMessage: string): never {
    throw new Error(errorMessage);
  }

  private defaultSlowSort(value: unknown) {
    switch (typeof value) {
      case 'number':
        return value;
      case 'boolean':
        return value ? '1' : '0';
      case 'string':
        return value.toLowerCase();
      default:
        return '';
    }
  }
}
