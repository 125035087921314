export function resizeImage(file: File, maxWidth: number, maxHeight: number, quality: number): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const { width, height } = image;

      if (width <= maxWidth && height <= maxHeight) {
        resolve(file);
      }

      const ratio = Math.min(maxWidth / width, maxHeight / height);
      const newWidth = width * ratio;
      const newHeight = height * ratio;

      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas.getContext('2d');
      context!.drawImage(image, 0, 0, newWidth, newHeight);

      canvas.toBlob(resolve, file.type, quality);
    };
    image.onerror = reject;
  });
}
export function isImage(fileName: string) {
  const ext = fileName.split('.')[1] ?? '';
  return ['jpg', 'png', 'gif', 'svg'].includes(ext);
}
