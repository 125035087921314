<input
  type="text"
  [disabled]="disabled"
  [colorPicker]="color"
  [(ngModel)]="color"
  name="color"
  [cpColorMode]="colorMode"
  [cpPresetColors]="presets ? presets : []"
  [cpOutputFormat]="'hex'"
  [cpAlphaChannel]="'disabled'"
  (colorPickerChange)="color = $event"
  [value]="color"
  [style.background]="color"
  [style.color]="color | textColor"
  [cpUseRootViewContainer]="true"
/>
