/*************************************************************************************************
 * WARNING: code used in generated typescript. Do not change without understanding consequence
 *************************************************************************************************/

/* eslint-disable no-restricted-syntax */
type safeStorage = Window & { saveStorage: Record<string, string | null> };
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const safeStorage = ((window as unknown as safeStorage).saveStorage ??= {});
// It looks that some browsers give no exception when reading but just return empty

export const safeLocalStorage = {
  getItem: (key: string): string | null => {
    try {
      return localStorage.getItem(key) ?? safeStorage[key] ?? null;
    } catch {
      return safeStorage[key] ?? null;
    }
  },
  setItem: (key: string, value: string) => {
    try {
      safeStorage[key] = value;
      localStorage.setItem(key, value);
    } catch {
      // already in saveStorage
    }
  },
  removeItem: (key: string) => {
    try {
      delete safeStorage[key];
      localStorage.removeItem(key);
    } catch {
      // already deleted
    }
  },
};
export const safeSessionStorage = {
  getItem: (key: string): string | null => {
    try {
      return sessionStorage.getItem(key) ?? safeStorage[key] ?? null;
    } catch {
      return safeStorage[key] ?? null;
    }
  },
  setItem: (key: string, value: string) => {
    try {
      safeStorage[key] = value;
      sessionStorage.setItem(key, value);
    } catch {
      // already in saveStorage
    }
  },
  removeItem: (key: string) => {
    try {
      delete safeStorage[key];
      sessionStorage.removeItem(key);
    } catch {
      // already deleted
    }
  },
  clear: () => {
    try {
      Object.keys(safeStorage).forEach((x) => delete safeStorage[x]);
      sessionStorage.clear();
    } catch {
      // already cleared
    }
  },
};
