<h3 class="ms-3 mt-2 mb-1">Theme: {{ theme?.name }}</h3>

<mat-stepper linear #stepper>
  <mat-step label="Palette">
    <div class="mb-3">
      <lsf-themes-editor-palette-picker *ngIf="theme"></lsf-themes-editor-palette-picker>
    </div>

    <div class="d-flex justify-content-end" *ngIf="showWizardActionButtons">
      <ng-container [ngTemplateOutlet]="wizardActionButtons"></ng-container>
    </div>
  </mat-step>

  <mat-step label="Text and background colours">
    <div class="mb-3">
      <lsf-themes-editor-text-legibility></lsf-themes-editor-text-legibility>
    </div>
    <div class="d-flex justify-content-end" *ngIf="showWizardActionButtons">
      <ng-container [ngTemplateOutlet]="wizardActionButtons"></ng-container>
    </div>
  </mat-step>

  <mat-step label="Font family">
    <div class="mb-3">
      <lsf-themes-editor-font-picker></lsf-themes-editor-font-picker>
    </div>
    <div class="d-flex justify-content-end" *ngIf="showWizardActionButtons">
      <ng-container [ngTemplateOutlet]="wizardActionButtons"></ng-container>
    </div>
  </mat-step>

  <mat-step label="Theme settings">
    <div class="mb-3">
      <lsf-themes-editor-settings [projectGuid]="projectGuid" (formId)="setFormId($event)"></lsf-themes-editor-settings>
    </div>
    <div class="d-flex justify-content-end" *ngIf="showWizardActionButtons">
      <ng-container [ngTemplateOutlet]="wizardActionButtons"></ng-container>
    </div>
  </mat-step>
</mat-stepper>

<ng-template #wizardActionButtons>
  <button type="button" mat-stroked-button color="primary" *ngIf="stepper.selectedIndex > 0" (click)="stepper.previous()" class="me-2">
    Previous step
  </button>
  <button type="button" mat-raised-button color="primary" (click)="stepper.next()" *ngIf="stepper.selectedIndex !== stepper.steps.length - 1">
    Next step
  </button>
  <button mat-raised-button color="primary" *ngIf="stepper.selectedIndex === stepper.steps.length - 1" [attr.form]="formId">Save theme</button>
</ng-template>
