import { APP_BASE_HREF, DOCUMENT, LocationStrategy, PlatformLocation } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, InjectionToken, Injector, Provider } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { InitialThemingService } from '@shared/feature/themes';
import { DialogAlertComponent } from '@shared/ui/dialog';
import { AppSettingsService, GlobalErrorHandlerService, getEnvironment, guidInterceptor } from '@shared/util/infrastructure';
import { preLoadLanguage } from '@shared/util/translations';
import { switchMap } from 'rxjs/operators';
import { loadingInterceptor } from './core/loading.interceptor';

export type ToolsBaseUrls = {
  surveyAdmin: string;
  surveyResponse: string;
  surveyEditor: string;
  reports: string;
  reportGroups: string;
};

export const PROJECT_GUID = new InjectionToken<string>('PROJECT_GUID');
export const API_PORTAL_BASE_URL = new InjectionToken<string>('API_PORTAL_BASE_URL');
export const API_EXPORT_BASE_URL = new InjectionToken<string>('API_EXPORT_BASE_URL');
export const API_NODEJS_BASE_URL = new InjectionToken<string>('API_NODEJS_BASE_URL');
export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
export const API_REGISTRATION_BASE_URL = new InjectionToken<string>('');
export const API_EDITOR_BASE_URL = new InjectionToken<string>('');
export const API_REPORT_BASE_URL = new InjectionToken<string>('');
export const API_SINGLE_REPORT_BASE_URL = new InjectionToken<string>('');
export const API_RESPONSE_BASE_URL = new InjectionToken<string>('');
export const API_ADMIN_BASE_URL = new InjectionToken<string>('');
export const TOOLS_BASE_URLS = new InjectionToken<ToolsBaseUrls>('');

// eslint-disable-next-line max-lines-per-function
export function infrastructureConfig(apiUrl: string): Provider | EnvironmentProviders {
  return [
    provideHttpClient(withInterceptors([guidInterceptor, loadingInterceptor])),
    DialogAlertComponent,
    {
      provide: ErrorHandler,
      useFactory: (injector: Injector, document: Document, router: Router, locationStrategy: LocationStrategy) =>
        new GlobalErrorHandlerService(document, injector, router, locationStrategy),
      deps: [Injector, DOCUMENT, Router, LocationStrategy],
    },
    {
      provide: TOOLS_BASE_URLS,
      useFactory: (document: Document) => getToolBaseUrls(document.location),
      deps: [DOCUMENT],
    },
    {
      provide: API_EXPORT_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.exportApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_REPORT_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.reportGroupsApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_SINGLE_REPORT_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.reportApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_PORTAL_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.portalApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_RESPONSE_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.responseApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_ADMIN_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.adminApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: PROJECT_GUID,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.projectGuid,
      deps: [AppSettingsService],
    },
    {
      provide: API_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.apiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_REGISTRATION_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.registrationApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_NODEJS_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.nodeJs,
      deps: [AppSettingsService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (settings: AppSettingsService, initialThemingService: InitialThemingService, translocoService: TranslocoService) => () =>
        settings.init(apiUrl).pipe(
          switchMap((x) => initialThemingService.initialize(x.portalApiUrl, settings.projectGuid)),
          switchMap(() => preLoadLanguage(translocoService)()),
        ),
      deps: [AppSettingsService, InitialThemingService, TranslocoService],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => (s.getBaseHrefFromDOM().endsWith('/') ? s.getBaseHrefFromDOM().slice(0, -1) : s.getBaseHrefFromDOM()),
      deps: [PlatformLocation],
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        restoreFocus: true,
        autoFocus: true,
        minWidth: 450,
        hasBackdrop: true,
        disableClose: true,
      },
    },
    { provide: MatBottomSheet }, // needed for bottomSheet reference in loading-indicator.service
  ];
}

const urlProviders: Provider[] = [
  {
    provide: TOOLS_BASE_URLS,
    useFactory: (document: Document) => getToolBaseUrls(document.location),
    deps: [DOCUMENT],
  },
  {
    provide: API_EXPORT_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.exportApiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_REPORT_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.reportGroupsApiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_SINGLE_REPORT_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.reportApiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_PORTAL_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.portalApiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_RESPONSE_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.responseApiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_ADMIN_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.adminApiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.apiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_REGISTRATION_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.registrationApiUrl,
    deps: [AppSettingsService],
  },
  {
    provide: API_NODEJS_BASE_URL,
    useFactory: (appSettingsService: AppSettingsService) => appSettingsService.nodeJs,
    deps: [AppSettingsService],
  },
];
export function getToolBaseUrls(location: Location): ToolsBaseUrls {
  return getEnvironment(location) === 'local'
    ? {
        surveyAdmin: `https://${location.hostname}:4201/SurveyAdmin/`,
        reports: `https://${location.hostname}:4207/Reports/`,
        reportGroups: `https://${location.hostname}:4205/ReportGroups/`,
        surveyEditor: `https://${location.hostname}:4203/SurveyEditor/`,
        surveyResponse: `https://${location.hostname}:4202/SurveyResponse/`,
      }
    : {
        surveyAdmin: `${location.origin}/SurveyAdmin/`,
        reports: `${location.origin}/Reports/`,
        reportGroups: `${location.origin}/ReportGroups/`,
        surveyEditor: `${location.origin}/SurveyEditor/`,
        surveyResponse: `${location.origin}/SurveyResponse/`,
      };
}
