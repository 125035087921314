export function getEnvironment(location: Location): 'local' | 'development' | 'test' | 'staging' | 'production' {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return !location.origin || location.origin.includes('localhost')
    ? 'local'
    : location.origin.includes('bs-local.com')
      ? 'local'
      : location.origin.includes('127.0.0.1')
        ? 'local'
        : location.origin.includes('-dev-')
          ? 'development'
          : location.origin.includes('-tst-')
            ? 'test'
            : location.origin.includes('-acc-')
              ? 'staging'
              : location.origin.includes('acc.')
                ? 'staging'
                : location.origin.includes('.acc')
                  ? 'staging'
                  : 'production';
}
