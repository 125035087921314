import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { UserManagementClient } from '@shared/data-access/common';
import { emailRegExp, ucFirst } from '@shared/util/code';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mxRecordValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: MxRecordValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class MxRecordValidatorDirective implements AsyncValidator {
  invalidEmail: { mxRecordValidator: string };
  @Input() required: string | null = null;
  constructor(
    private transLocoService: TranslocoService,
    private userManagementClient: UserManagementClient,
    private cdr: ChangeDetectorRef,
  ) {
    this.invalidEmail = { mxRecordValidator: ucFirst(this.trl('_errors_invalid_email')) };
  }
  @Input() valueFunc: (value: string | Record<string, string>) => string = (value) => value as string;

  trl(text: string, field?: string) {
    if (field) {
      field = ucFirst(this.transLocoService.translate(field));
    }
    return ucFirst(this.transLocoService.translate(text, { field }));
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const currentValue = this.valueFunc(control.value);
    if (this.required === null && !currentValue) {
      return of(null);
    }
    return this.hasValidEmailFormat(currentValue)
      ? control.valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((x) => this.userManagementClient.hasValidMxRecord(encodeURI(this.valueFunc(x)))),
          map((x) => (x ? null : this.invalidEmail)),
          tap(() => this.cdr.markForCheck()), // needed to inform parent controls about changes
          first(),
        )
      : of(this.invalidEmail);
  }

  private hasValidEmailFormat = (email: string): boolean => emailRegExp.test(email);
}
