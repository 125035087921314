<div class="mat-subtitle-2">
  The contrasts in the table are based on WCAG 2.0 level AA. When a contrast is not satisfactory, a warning icon will be shown. The theme can be saved
  but this is not recommended.
</div>

<div class="d-flex flex-column">
  <div class="d-flex align-items-center mat-subtitle-2 p-1">
    <div class="w-50">Text colour</div>
    <div class="w-25 d-flex align-items-center">
      <span class="mat-headline-6 mb-0 me-1">Aa</span>
      <span class="me-1">large text</span>
      <mat-icon matTooltip="Colour contrast ratio: 4.5:1 relative to the background (WCAG 2.0 level AA).">info</mat-icon>
    </div>
    <div class="w-25 d-flex align-items-center">
      <span class="mat-subtitle-2 mb-0 me-1">Aa</span>
      <span class="me-1">Normal text</span>
      <mat-icon matTooltip="Colour contrast ratio: 3:1 relative to the background (WCAG 2.0 level AA).">info</mat-icon>
    </div>
  </div>

  <div [ngStyle]="{ 'background-color': mainColors.primaryColor, 'color': textColors.primaryColor }" class="d-flex align-items-center p-1">
    <div class="w-50">On primary colour</div>
    <div class="w-25" [style.color]="mainColors.primaryColor | textColor">
      <ng-container
        [ngTemplateOutlet]="isLegibleTemplate"
        [ngTemplateOutletContext]="{ background: mainColors.primaryColor, textColor: textColors.primaryColor, size: 'small' }"
      ></ng-container>
    </div>
    <div class="w-25" [style.color]="mainColors.primaryColor | textColor">
      <ng-container
        [ngTemplateOutlet]="isLegibleTemplate"
        [ngTemplateOutletContext]="{ background: mainColors.primaryColor, textColor: textColors.primaryColor, size: 'large' }"
      ></ng-container>
    </div>
  </div>
  <div [ngStyle]="{ 'background-color': mainColors.accentColor, 'color': textColors.accentColor }" class="d-flex align-items-center p-1">
    <div class="w-50">On accent colour</div>
    <div class="w-25" [style.color]="mainColors.accentColor | textColor">
      <ng-container
        [ngTemplateOutlet]="isLegibleTemplate"
        [ngTemplateOutletContext]="{ background: mainColors.accentColor, textColor: textColors.accentColor, size: 'small' }"
      ></ng-container>
    </div>
    <div class="w-25" [style.color]="mainColors.accentColor | textColor">
      <ng-container
        [ngTemplateOutlet]="isLegibleTemplate"
        [ngTemplateOutletContext]="{ background: mainColors.accentColor, textColor: textColors.accentColor, size: 'large' }"
      ></ng-container>
    </div>
  </div>

  <div [ngStyle]="{ 'background-color': backgroundColors.lightColor }" class="d-flex align-items-center p-1">
    <div class="w-50">On background</div>
    <div class="w-25" [style.color]="backgroundColors.lightColor | textColor">
      <ng-container
        [ngTemplateOutlet]="isLegibleTemplate"
        [ngTemplateOutletContext]="{ background: backgroundColors.lightColor, textColor: 'rgba(0,0,0,.87)', size: 'small' }"
        ]
      ></ng-container>
    </div>
    <div class="w-25" [style.color]="backgroundColors.lightColor | textColor">
      <ng-container
        [ngTemplateOutlet]="isLegibleTemplate"
        [ngTemplateOutletContext]="{ background: backgroundColors.lightColor, textColor: 'rgba(0,0,0,.87)', size: 'large' }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #isLegibleTemplate let-background="background" let-textColor="textColor" let-size="size">
  <div class="d-flex align-items-center">
    <span class="pe-2">{{ (textColor | isLegible: { backgroundColor: background, size: size }) ? "READABLE" : "Not READABLE" }}</span>
    <mat-icon
      [ngClass]="{
        visible: !(textColor | isLegible: { backgroundColor: background, size: size }),
        invisible: (textColor | isLegible: { backgroundColor: background, size: size }),
      }"
      >warning
    </mat-icon>
  </div>
</ng-template>
