/* eslint-disable max-lines */
import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { ColorFunc, DisabledFunc, ValueFunc } from '../Interfaces/IColumnBase';
import { IDynamicTableEvents } from '../table/table-events.component';
import { IColumnDefinition } from './column-extender';
import { IMatCellHostComponent } from './mat-cell-host.directive';
import { TooltipDirective } from './tooltip.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-cell-checkbox',
  templateUrl: './mat-cell-checkbox.component.html',
  standalone: true,
  imports: [MatCheckboxModule, TooltipDirective],
})
export class MatCellCheckboxComponent<TRowData, TRowVirtual> implements IMatCellHostComponent<TRowData, TRowVirtual>, OnInit {
  valueFunc!: ValueFunc<TRowData>;
  colorFunc!: ColorFunc<TRowData>;
  disabledFunc!: DisabledFunc<TRowData>;

  @Input() host!: IDynamicTableEvents<TRowData, TRowVirtual>;
  @Input() row!: TRowData;
  @Input() index!: number;
  @Input() column!: IColumnDefinition<TRowData, TRowVirtual>;

  ngOnInit(): void {
    const column = this.column;
    this.valueFunc = column._valueFunc;
    this.colorFunc = column._colorFunc;
    this.disabledFunc = column._disabledFunc;
  }

  change(event: MatCheckboxChange): void {
    const host = this.host;
    const column = this.column;
    const data = { row: this.row, column, checked: event.checked, matCheckboxChange: event };
    if (column.isRowSelector) {
      (this.row as unknown as { selector: boolean }).selector = event.checked;
      host.selectionChanged.emit();
    } else {
      host.checkBoxChanged.emit(data);
    }
  }
}
