<lsuCloseOnEscape></lsuCloseOnEscape>
<h1 mat-dialog-title>Available font</h1>
<mat-dialog-content>
  <div class="d-flex align-items-center">
    <div class>Below is an overview of all available fonts (<a href="https://fonts.google.com" target="_blank">source</a>).</div>
    <mat-form-field class="mat-caption ms-auto">
      <input type="text" #input matInput name="filter" placeholder="Search font" (input)="filter = input.value" />
    </mat-form-field>
  </div>
  <mat-tab-group #tabGroup>
    <mat-tab label="Sans-Serif" #ssTab>
      <cdk-virtual-scroll-viewport itemSize="50" *ngIf="ssTab.isActive">
        <lsf-themes-editor-google-font-item
          *cdkVirtualFor="let f of getFonts('sans-serif') | async"
          (click)="onPickFont(f)"
          [selectedFamily]="selectedFamily"
          [fontMeta]="f"
          [sample]="sample"
        ></lsf-themes-editor-google-font-item>
      </cdk-virtual-scroll-viewport>
    </mat-tab>
    <mat-tab label="Serif" #sTab>
      <cdk-virtual-scroll-viewport itemSize="50" *ngIf="sTab.isActive">
        <lsf-themes-editor-google-font-item
          *cdkVirtualFor="let f of getFonts('serif') | async"
          (click)="onPickFont(f)"
          [fontMeta]="f"
          [selectedFamily]="selectedFamily"
          [sample]="sample"
        ></lsf-themes-editor-google-font-item>
      </cdk-virtual-scroll-viewport>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="me-2" mat-raised-button color="primary" [mat-dialog-close]="selectedFamily">Select font</button>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
