export enum UserRoles {
  Administrator = 'Administrator',
  SurveyAdministrator = 'SurveyAdministrator',
  Manager = 'Manager',
  Helpdesk = 'Helpdesk',
  Respondent = 'Respondent',
  User = 'User',
}

export function hasRoleWithinAdminPolicy(role: string) {
  return UserRoles.Administrator === role;
}

export function hasRoleWithinSurveyAdminPolicy(role: string) {
  return hasRoleWithinAdminPolicy(role) || UserRoles.SurveyAdministrator === role;
}

export function hasRoleWithinManagerPolicy(role: string) {
  return hasRoleWithinSurveyAdminPolicy(role) || UserRoles.Manager === role;
}

export function isUser(role: string) {
  return UserRoles.User === role;
}

export const RoleEN: Record<string, string> = {
  Administrator: 'Administrator',
  User: 'User',
  Manager: 'Manager',
  SurveyAdministrator: 'Survey admin',
  Helpdesk: 'Helpdesk',
  Respondent: 'Respondent',
};
