import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ucFirst } from '@shared/util/code';
import { TRANSLATED_ROLES } from '@shared/util/translations';
import { NgStringPipesModule } from 'ngx-pipes';
import { CurrentOrImpersonateUser, UserService } from '../core/user-claim-data';

@Component({
  selector: 'lsu-user-last-login-details',
  templateUrl: './last-login-details.component.html',
  standalone: true,
  imports: [NgIf, DatePipe, TranslocoModule, NgStringPipesModule],
})
export class LastLoginDetailsComponent {
  readonly ucfirst = ucFirst;
  role?: string;
  lastLoginDate!: Date;
  name!: string;

  constructor(
    userService: UserService,
    public translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    @Inject(TRANSLATED_ROLES) private translatedRole?: Record<string, string>,
  ) {
    userService.currentOrImpersonateUser$.pipe(takeUntilDestroyed()).subscribe((x) => this.refreshComponent(x));

    if (document.cookie.includes('differentuser=')) {
      this.snackBar.open(this.translocoService.translate('errors._sentences.Email_is_different'));
    }
  }

  private refreshComponent(user: CurrentOrImpersonateUser) {
    this.lastLoginDate = user.lastLoginDate;
    this.name = user.name;
    this.role = this.translatedRole ? this.translatedRole[user.role] : user.role;
    this.cdr.markForCheck();
  }
}
