/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { Translation, TranslocoConfig, TranslocoLoader } from '@jsverse/transloco';
import { AppInsightsService, AppSettingsService, UserError, getEnvironment } from '@shared/util/infrastructure';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  static fallbackLanguage = 'en-GB';
  constructor(private appSettingsService: AppSettingsService) {}

  static getTransLocoConfig(prodMode: boolean, lang: string): Partial<TranslocoConfig> {
    return {
      availableLangs: ['nl-NL', 'en-GB'],
      fallbackLang: TranslocoHttpLoader.fallbackLanguage,
      defaultLang: lang,
      missingHandler: { useFallbackTranslation: false, logMissingKey: true, allowEmpty: true },
      reRenderOnLangChange: false,
      prodMode: prodMode,
    };
  }

  // http.get is not possible, it adds an authorization header which is not recognized by Azure.
  async getTranslation(lang: string): Promise<Translation> {
    // truncate to first 5 locale characters, filter nl-NL-jevorm -> nl-NL, /en-GB -> en-GB)
    const startPosition = lang.search(/[a-z]/);
    lang = lang.substring(startPosition, startPosition + 5);
    const url = this.appSettingsService.getLanguageJsonUrl(lang);
    try {
      const result: ProgressEvent<EventTarget> = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.timeout = 10000;
        xhr.onload = resolve;
        xhr.onerror = reject;
        xhr.send(null);
      });
      const httpResult = result.target! as unknown as XMLHttpRequest;
      if (httpResult.status === 404) {
        if (lang !== TranslocoHttpLoader.fallbackLanguage) {
          // The language file could not be found. This is either because the root server is not started or the language file does not exist.
          // Try to use the default language (en-GB in this case), if this one exists then at least the application does not crash or give errors.
          // Otherwise the root server may not be started.
          AppInsightsService.trackTraceError(` Requested language not found '${lang}' fallback to '${TranslocoHttpLoader.fallbackLanguage}'`);
          return await this.getTranslation(TranslocoHttpLoader.fallbackLanguage);
        } else {
          AppInsightsService.trackTraceError(`Fatal error!! Fallback language not found '${TranslocoHttpLoader.fallbackLanguage}'`);
          return {} as Translation;
        }
      }
      if (httpResult.status !== 200) {
        AppInsightsService.trackTraceError(`getTranslation has status ='${httpResult.status}'`);
      }

      return JSON.parse(httpResult.response) as Translation;
    } catch {
      if (getEnvironment(window.location) === 'local') {
        throw new UserError(
          `Failed to load http://localhost:5600/translations/${lang}.json.
          <br /><br />
          Check the following:
          <br />
          -is the <i>Portal/Root/Root.Webserver</i> started
          <br />
          -does <i>${lang}.json</i> exist in <i>Root.webserver/wwwroot/translations</i>.`,
          'Something went wrong',
        );
      } else {
        AppInsightsService.trackTraceError(`Failed to load language file <i>${url}</i>.`);
        return {} as Translation;
      }
    }
  }
}
