import { Injectable } from '@angular/core';
import { InlineEditButtonComponent, ItemData } from './inline-edit-button.component';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  items: ItemData[] = [];

  addCol(row: unknown, name: string, comp: InlineEditButtonComponent<unknown, unknown>) {
    const item = this.items.find((x) => x.row === row) ?? this.createItem(row);
    item.cols[name] = comp;
  }

  removeCol(row: unknown, name: string) {
    const items = this.items;
    const item = items.find((x) => x.row === row)!;
    delete item.cols[name];
    if (Object.keys(item.cols).length === 0) {
      const index = items.indexOf(item);
      items.splice(index, 1);
    }
  }

  clickNext(row: unknown, name: string, direction: number) {
    const item = this.items.find((x) => x.row === row)!;
    const keys = Object.keys(item.cols);
    if (keys.length < 2) {
      return;
    }
    let index = keys.indexOf(name) + direction;
    if (index === keys.length) {
      index = 0;
    }
    if (index < 0) {
      index = keys.length - 1;
    }
    Object.values(item.cols)[index].onInlineEditClick();
  }

  private createItem(row: unknown) {
    const item = { row, cols: {} } as ItemData;
    this.items.push(item);
    return item;
  }
}
