export interface ColorConfig {
  colorVariant: string;
  colorHexValue: string;
  shouldHaveDarkContrast: boolean;
}

export type DarkLight = {
  [key in DarkLightType]: string;
};

export type MainColors = {
  [key in ThemeMainColorType]: string;
};

export interface TextColors {
  primaryColor: string;
  accentColor: string;
}

export interface BackgroundColors {
  lightColor: string;
  darkColor: string;
}

export type ColorPalette = Record<ThemeMainColorType, ColorConfig[]>;

export enum ThemeMainColorType {
  primaryColor = 'primaryColor',
  accentColor = 'accentColor',
  warnColor = 'warnColor',
}

export enum ThemeBackgroundColorType {
  lightColor = 'lightColor',
  darkColor = 'darkColor',
}

export enum ThemeTextColorType {
  primaryColor = 'primaryColor',
  accentColor = 'accentColor',
}

export enum DarkLightType {
  dark = 'dark',
  light = 'light',
}
