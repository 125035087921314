import { AppInsightsService } from '@shared/util/infrastructure';
import { IColumn } from '../Interfaces/IColumn';
import { DisplayFunc } from '../Interfaces/IColumnBase';

export const intDecimalFormat = new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format;
export const intCurrencyFormat = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format;
export const intShortDateFormat = new Intl.DateTimeFormat('nl-NL', { dateStyle: 'short' }).format;
export const intShortDateTimeFormat = new Intl.DateTimeFormat('nl-NL', { dateStyle: 'short', timeStyle: 'short' }).format;

// displayFunc is used for filtering and displaying the value in the cell.
// The valueFunc is used for sorting and filtering
// Components (checkbox, menu) must also return strings to support wildcard filtering .includes() and .toLower()
export const getDisplayFunc = <TRowData, TRowVirtual>(column: IColumn<TRowData, TRowVirtual>): DisplayFunc<TRowData> => {
  if (column.displayFunc) {
    return column.displayFunc as DisplayFunc<TRowData>;
  }
  const type = column.type;
  const valueFunc = column.valueFunc;
  if (valueFunc) {
    switch (type) {
      case 'currency':
        return (row: TRowData) => formatToCurrency(valueFunc(row));
      case 'date':
        return (row: TRowData) => formatToDate(valueFunc(row));
      case 'datetime':
        return (row: TRowData) => formatToDateTime(valueFunc(row));
      case 'decimal':
        return (row: TRowData) => formatToNumber(valueFunc(row));
      case 'menu':
      case 'component': // menu and component has no displayFormat and cannot converted to strings
        return () => '';
    }
    return (row: TRowData) => (valueFunc(row) ?? '') + '';
  }
  const col = column.name as keyof TRowData;
  switch (type) {
    case 'currency':
      return (row: TRowData) => formatToCurrency(row[col]);
    case 'decimal':
      return (row: TRowData) => formatToNumber(row[col]);
    case 'date':
      return (row: TRowData) => formatToDate(row[col]);
    case 'datetime':
      return (row: TRowData) => formatToDateTime(row[col]);
    case 'menu':
    case 'component': // menu and component has no displayFormat and cannot converted to strings
      return () => '';
    default:
      return (row: TRowData) => ((row[col] as unknown) ?? '') + '';
  }
};

function formatToCurrency(value: unknown) {
  return typeof value === 'number' ? intCurrencyFormat(value) : value === null ? '' : logError('Type is not a number', value);
}

function formatToNumber(value: unknown) {
  return typeof value === 'number' ? intDecimalFormat(value) : value === null ? '' : logError('Type is not a number', value);
}

function formatToDateTime(value: unknown) {
  return typeof value === 'string' || typeof value === 'number' || value instanceof Date
    ? intShortDateTimeFormat(new Date(value))
    : value === null
      ? ''
      : logError('Type is not valid date', value);
}

function formatToDate(value: unknown): string {
  return typeof value === 'string' || typeof value === 'number' || value instanceof Date
    ? intShortDateFormat(new Date(value))
    : value === null
      ? ''
      : logError('Type is not valid date', value);
}

function logError(msg: string, value: unknown) {
  AppInsightsService.trackTraceError(`${msg}:${typeof value}:'${value}'`);
  return '';
}
