import { Injectable } from '@angular/core';
import { IColumn } from '../Interfaces/IColumn';
import { createColumnStyleRules } from '../helpers/style-rules';

@Injectable({ providedIn: 'root' })
export class StyleRuleService {
  styleSheet = new Set();

  initStyle<T1, T2>(columnDefinitions: IColumn<T1, T2>[], tableId: string) {
    const rules = createColumnStyleRules(columnDefinitions, tableId);
    this.ensureStyleSheet(tableId, rules);
  }

  private ensureStyleSheet(tableId: string, rules: string[]) {
    if (this.styleSheet.has(tableId)) {
      return;
    }
    const style = document.createElement('style');
    document.head.appendChild(style);
    rules.forEach((x) => style.sheet!.insertRule(x));

    this.styleSheet.add(tableId);
  }
}
