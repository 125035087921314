import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FontService } from '../../../core/font.service';
import { FontMeta } from '../../../shared/models/font.model';

@Component({
  selector: 'lsf-themes-editor-google-font-item',
  templateUrl: './google-font-item.component.html',
  styleUrls: ['./google-font-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GoogleFontItemComponent {
  @Input() sample: string = null!;
  @Input() selectedFamily: string = null!;

  @Input() set fontMeta(fontMeta: FontMeta) {
    this._fontMeta = fontMeta;
    this.fontService.loadFont(fontMeta.family);
  }

  get fontMeta() {
    return this._fontMeta;
  }

  private _fontMeta: FontMeta = null!;

  constructor(private fontService: FontService) {}

  @HostBinding('class.active') get isActive() {
    return this.selectedFamily === this.fontMeta.family;
  }
}
