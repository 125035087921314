import { TranslocoService } from '@jsverse/transloco';
import { defaultValues, getItemProjectSpecific } from '@shared/util/code';

export function preLoadLanguage(translocoService: TranslocoService) {
  return function () {
    let lang = getItemProjectSpecific(defaultValues.locale)?.substring(0, 5) || translocoService.getActiveLang();
    lang = !(translocoService.getAvailableLangs() as string[]).includes(lang) ? translocoService.getDefaultLang() : lang;
    translocoService.setActiveLang(lang);
    return translocoService.load(lang);
  };
}
